import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './InscriptionForm.css';
import PillAnimation from './PillAnimation'; 
import ConfirmPurchaseModal from './ConfirmPurchaseModal'; 

const BACKEND_URL = 'https://inscription-service-production.up.railway.app';


const satoshiToBitcoin = (satoshis) => {
    return (satoshis / 100000000).toFixed(8); 
};

const InscriptionForm = () => {
    const [file, setFile] = useState(null);
    const [orderDetails, setOrderDetails] = useState({
        totalAmount: 0,
        totalAmountBTC: 0, 
        payAddress: '',
        receiverAddress: '',
        devAddress: '',
        devFee: 0,
        feeRate: 10,
    });
    const [recommendedFees, setRecommendedFees] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false); 

    useEffect(() => {
        const fetchRecommendedFees = async () => {
            try {
                const response = await axios.get('https://mempool.space/api/v1/fees/recommended');
                setRecommendedFees(response.data);
            } catch (error) {
                console.error('Error fetching recommended fees:', error);
            }
        };

        fetchRecommendedFees();
    }, []);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleFeeRateChange = (event) => {
        setOrderDetails({ ...orderDetails, feeRate: event.target.value });
    };

    const handleReceiverAddressChange = (event) => {
        setOrderDetails({ ...orderDetails, receiverAddress: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            alert("Please select a file.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        

        try {
            const response = await fetch(`${BACKEND_URL}/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    
                    'User-Selected-Fee-Rate': orderDetails.feeRate,
                    'User-Selected-Receiver-Address': orderDetails.receiverAddress,
                }
            });

            if (response.ok) {
                const result = await response.json();
                const bitcoinAmount = satoshiToBitcoin(result.payAddressAmount); 

                setOrderDetails({
                    ...orderDetails,
                    totalAmount: result.payAddressAmount,
                    totalAmountBTC: bitcoinAmount, 
                    payAddress: result.payAddress,
                    receiverAddress: result.receiverAddress,
                    devAddress: result.devAddress,
                    devFee: result.devFee,
                    feeRate: result.feeRate
                });

                setIsModalOpen(true); 
            } else {
                alert('Failed to upload file and create order. Please try again.');
            }
        } catch (error) {
            alert('An error occurred while processing your request.');
            console.error("Error during the fetch request:", error);
        }
    };

    const handleConfirm = async () => {
        if (window.unisat && window.unisat.sendBitcoin) {
            try {
                const txidPay = await window.unisat.sendBitcoin(orderDetails.payAddress, orderDetails.totalAmount, { feeRate: parseInt(orderDetails.feeRate) });
                console.log('Payment transaction successful. TXID:', txidPay);
                alert('Transaction successful! TXID: ' + txidPay);
                setIsModalOpen(false); 
            } catch (error) {
                console.error('Transaction failed:', error);
                alert('Transaction failed. Please try again or check your wallet.');
            }
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false); 
    };

    return (
        <div className="inscription-form-container">
            <PillAnimation />
            <div className="inscription-form">
                <form onSubmit={handleSubmit}>
                    <div className="file-upload-container">
                        <label htmlFor="file-upload" className="custom-file-upload">
                            Upload File
                        </label>
                        <input id="file-upload" type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                    </div>
                    <div>
                        <label>Receiver Address:</label>
                        <input type="text" value={orderDetails.receiverAddress} onChange={handleReceiverAddressChange} placeholder="Enter receiver address" />
                    </div>
                    <div>
                        <label>Fee Rate (sat/vB):</label>
                        <input type="number" value={orderDetails.feeRate} onChange={handleFeeRateChange} min="1" />
                        {recommendedFees.fastestFee && (
                            <div>
                                <p>Fastest Fee: {recommendedFees.fastestFee} sat/vB</p>
                                <p>Half Hour Fee: {recommendedFees.halfHourFee} sat/vB</p>
                                <p>Hour Fee: {recommendedFees.hourFee} sat/vB</p>
                            </div>
                        )}
                    </div>
                    {file && (
                        <div>
                            <p>File Name: {file.name}</p>
                            <p>File Size: {file.size} bytes</p>
                            <p>Total Fee: {orderDetails.totalAmount} satoshis</p>
                            <p>Network Fee Rate: {orderDetails.feeRate} sat/vB</p>
                        </div>
                    )}
                    <button type="submit">Submit Inscription</button>
                </form>
            </div>
            <ConfirmPurchaseModal
                isOpen={isModalOpen}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                paymentDetails={{
                    payAddressAmount: orderDetails.totalAmount, 
                    payAddress: orderDetails.payAddress,
                    receiverAddress: orderDetails.receiverAddress,
                    feeRate: orderDetails.feeRate
                }}
            />
        </div>
    );
};

export default InscriptionForm;
