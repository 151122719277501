import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { minify } from 'terser';
import * as fflate from 'fflate';
import './VideoCompressMagic.css';
import ConfirmPurchaseModal from './ConfirmPurchaseModal';

const BACKEND_URL = 'https://inscription-service-production.up.railway.app';

const SavingsModal = ({ isOpen, onClose, savings }) => {
    if (!isOpen) return null;
    return (
        <div className="modal">
            <div className="modal-content">
                <h4>Compression Savings</h4>
                <p>You saved {savings}% in file size by compressing your code.</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

const VideoCompressMagic = () => {
    const [videoFile, setVideoFile] = useState(null);
    const [inputCode, setInputCode] = useState('');
    const [finalHtml, setFinalHtml] = useState('');
    const [orderDetails, setOrderDetails] = useState({
        totalAmount: 0,
        totalAmountBTC: '',
        payAddress: '',
        receiverAddress: '',
        devAddress: '',
        devFee: 0,
        feeRate: 10,
    });
    const [recommendedFees, setRecommendedFees] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [savingsModalOpen, setSavingsModalOpen] = useState(false);
    const [savings, setSavings] = useState(0);
    const [iframeKey, setIframeKey] = useState(0);
    const iframeRef = useRef(null);

    useEffect(() => {
        const fetchRecommendedFees = async () => {
            try {
                const response = await axios.get('https://mempool.space/api/v1/fees/recommended');
                setRecommendedFees(response.data);
            } catch (error) {
                console.error('Error fetching recommended fees:', error);
            }
        };
        fetchRecommendedFees();
    }, []);

    const kmeans = (data, k) => {
        const centroids = data.slice(0, k);
        let clusters = new Array(data.length).fill(0);
        let newCentroids = Array.from({ length: k }, () => [0, 0, 0]);
        let changed = true;

        while (changed) {
            changed = false;

            for (let i = 0; i < data.length; i++) {
                const distances = centroids.map(centroid =>
                    Math.sqrt(data[i].reduce((sum, val, idx) => sum + Math.pow(val - centroid[idx], 2), 0))
                );
                const newCluster = distances.indexOf(Math.min(...distances));
                if (newCluster !== clusters[i]) {
                    clusters[i] = newCluster;
                    changed = true;
                }
            }

            newCentroids = Array.from({ length: k }, () => [0, 0, 0]);
            const counts = new Array(k).fill(0);
            for (let i = 0; i < data.length; i++) {
                newCentroids[clusters[i]] = newCentroids[clusters[i]].map((val, idx) => val + data[i][idx]);
                counts[clusters[i]]++;
            }
            for (let i = 0; i < k; i++) {
                if (counts[i] > 0) {
                    newCentroids[i] = newCentroids[i].map(val => Math.floor(val / counts[i]));
                }
            }
            centroids.forEach((_, idx) => centroids[idx] = newCentroids[idx]);
        }
        return { clusters, centroids };
    };

    const processFrame = (video, targetSize, colorClusters) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = targetSize;
        canvas.height = targetSize;

        ctx.drawImage(video, 0, 0, targetSize, targetSize);
        const imageData = ctx.getImageData(0, 0, targetSize, targetSize);
        const pixels = [];
        for (let y = 0; y < imageData.height; y++) {
            for (let x = 0; x < imageData.width; x++) {
                const index = (x + y * imageData.width) * 4;
                const r = imageData.data[index];
                const g = imageData.data[index + 1];
                const b = imageData.data[index + 2];
                pixels.push([r, g, b]);
            }
        }

        const { clusters, centroids } = kmeans(pixels, colorClusters);
        const encodedPixels = clusters.map(cluster => {
            const [r, g, b] = centroids[cluster];
            return `${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
        });

        return encodedPixels.join(';');
    };

    const processVideoFrames = async (video, frameRate, targetSize, colorClusters) => {
        const frames = [];
        const interval = 1 / frameRate;
        const totalFrames = Math.floor(video.duration * frameRate);

        for (let i = 0; i < totalFrames; i++) {
            video.currentTime = i * interval;
            await new Promise(resolve => video.onseeked = resolve);
            frames.push(processFrame(video, targetSize, colorClusters));
        }

        return frames;
    };

    const generateP5Code = useCallback(async () => {
        if (!videoFile) return;

        const video = document.createElement('video');
        video.src = videoFile;
        video.crossOrigin = 'anonymous';

        await new Promise((resolve) => {
            video.onloadeddata = () => {
                resolve();
            };
        });

        const targetSize = 50;  // Reduced target size for smaller data
        const colorClusters = 8;  // Fewer clusters for simpler data
        const frameRate = 15;  // Reduced frame rate for fewer frames

        const frames = await processVideoFrames(video, frameRate, targetSize, colorClusters);

        const code = `
let encodedFrames = ${JSON.stringify(frames)};
let currentFrame = 0;

function hexToRgb(hex) {
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    return [r, g, b];
}

function setup() {
    createCanvas(windowWidth, windowHeight);
    frameRate(${frameRate});
    noStroke();
    pixelDensity(1);
}

function draw() {
    background(0);
    let encodedPixels = encodedFrames[currentFrame].split(';');
    let imgWidth = ${targetSize};
    let imgHeight = ${targetSize};
    let scaleWidth = width / imgWidth;
    let scaleHeight = height / imgHeight;

    for (let y = 0; y < imgHeight; y++) {
        for (let x = 0; x < imgWidth; x++) {
            let index = x + y * imgWidth;
            let colorHex = encodedPixels[index];
            let [r, g, b] = hexToRgb(colorHex);
            fill(r, g, b);
            rect(x * scaleWidth, y * scaleHeight, ceil(scaleWidth), ceil(scaleHeight));
        }
    }

    currentFrame = (currentFrame + 1) % encodedFrames.length;
}
`;

        setInputCode(code);
        setIframeKey(prevKey => prevKey + 1);
    }, [videoFile]);

    const displaySketch = useCallback(() => {
        if (!inputCode.trim()) return;

        const fullHTML = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <script src="https://ordinals.com/content/7e37766541506810ba6399c4b2735121f508bd9209df43dd200bf2316b014594i0"></script>
                <style>
                    html, body { margin: 0; padding: 0; }
                    canvas { position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); }
                </style>
            </head>
            <body>
                <script>
                    ${inputCode}
                </script>
            </body>
            </html>
        `;

        const iframeDoc = iframeRef.current.contentDocument;
        iframeDoc.open();
        iframeDoc.write(fullHTML);
        iframeDoc.close();
    }, [inputCode]);

    useEffect(() => {
        displaySketch();
    }, [iframeKey, displaySketch]);

    const handleCompressAndGenerateHTML = async () => {
        try {
            const minifiedResult = await minify(inputCode);
            if (minifiedResult.error) {
                console.error('Minification error:', minifiedResult.error);
                return;
            }
            const originalSize = new TextEncoder().encode(inputCode).length;
            const compressed = fflate.gzipSync(new TextEncoder().encode(minifiedResult.code));
            const compressedSize = compressed.length;
            const base64Encoded = btoa(String.fromCharCode(...compressed));
            const htmlContent = `
    <script>
        import("/content/d795ba6cf2ea7d4ed9c159e498ba2c9ad4295d8ea257fb1ee88e9244c016adc2i0")
        .then(ob1 => ob1.ob1Scribe("${base64Encoded}"));
    </script>
            `;
            setFinalHtml(htmlContent);
            const savingsPercentage = ((originalSize - compressedSize) / originalSize * 100).toFixed(2);
            setSavings(savingsPercentage);
            setSavingsModalOpen(true);
        } catch (error) {
            console.error("Minification and compression error:", error);
        }
    };

    const handleReceiverAddressChange = (event) => {
        setOrderDetails({ ...orderDetails, receiverAddress: event.target.value });
    };

    const handleFeeRateChange = (event) => {
        setOrderDetails({ ...orderDetails, feeRate: event.target.value });
    };

    const handleSubmit = async () => {
        if (!finalHtml) {
            alert("Please generate the HTML first.");
            return;
        }

        const blob = new Blob([finalHtml], { type: 'text/html' });
        const formData = new FormData();
        formData.append("file", blob, 'compressedCode.html');

        try {
            const response = await fetch(`${BACKEND_URL}/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    'User-Selected-Fee-Rate': orderDetails.feeRate,
                    'User-Selected-Receiver-Address': orderDetails.receiverAddress,
                }
            });

            if (response.ok) {
                const result = await response.json();
                setOrderDetails({
                    ...orderDetails,
                    totalAmount: result.payAddressAmount,
                    totalAmountBTC: result.payAddressAmount / 100000000,
                    payAddress: result.payAddress,
                    receiverAddress: result.receiverAddress,
                    devAddress: result.devAddress,
                    devFee: result.devFee,
                    feeRate: result.feeRate
                });
                setIsModalOpen(true);
            } else {
                alert('Failed to upload HTML and create order. Please try again.');
            }
        } catch (error) {
            alert('An error occurred while processing your request.');
            console.error("Error during the fetch request:", error);
        }
    };

    const handleConfirm = async () => {
        if (window.unisat && window.unisat.sendBitcoin) {
            try {
                const txidPay = await window.unisat.sendBitcoin(orderDetails.payAddress, orderDetails.totalAmount, { feeRate: parseInt(orderDetails.feeRate) });
                console.log('Payment transaction successful. TXID:', txidPay);
                alert('Transaction successful! TXID: ' + txidPay);
                setIsModalOpen(false);
            } catch (error) {
                console.error('Transaction failed:', error);
                alert('Transaction failed. Please try again or check your wallet.');
            }
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="container mx-auto text-center">
            <h1 className="title text-xl font-bold mb-1">Video Compress Magic</h1>
            <p className="text-xs mb-4">Generate p5.js Code from Video and Compress</p>
            <input type="file" accept="video/*" onChange={e => setVideoFile(URL.createObjectURL(e.target.files[0]))} />
            <button className="button" onClick={generateP5Code}>Generate p5.js Code</button>
            <button className="button" onClick={handleCompressAndGenerateHTML}>Compress & Generate HTML</button>
            <SavingsModal isOpen={savingsModalOpen} onClose={() => setSavingsModalOpen(false)} savings={savings} />
            <div className="iframe-container">
                <h3>Ordinal Content Viewer:</h3>
                <iframe
                    key={iframeKey}
                    ref={iframeRef}
                    style={{ width: '100%', height: '500px', border: '1px solid black' }}
                    title="p5.js Sketch Output"
                />
            </div>
            <div>
                <label>Receiver Address:</label>
                <input type="text" value={orderDetails.receiverAddress} onChange={handleReceiverAddressChange} placeholder="Enter receiver address" />
            </div>
            <div>
                <label>Fee Rate (sat/vB):</label>
                <input type="number" value={orderDetails.feeRate} onChange={handleFeeRateChange} min="1" />
                {recommendedFees.fastestFee && (
                    <div>
                        <p>Fastest Fee: {recommendedFees.fastestFee} sat/vB</p>
                        <p>Half Hour Fee: {recommendedFees.halfHourFee} sat/vB</p>
                        <p>Hour Fee: {recommendedFees.hourFee} sat/vB</p>
                    </div>
                )}
            </div>
            <button className="button" onClick={handleSubmit} disabled={!finalHtml}>Inscribe Compressed Code</button>
            <ConfirmPurchaseModal
                isOpen={isModalOpen}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                paymentDetails={{
                    payAddressAmount: orderDetails.totalAmount,
                    payAddress: orderDetails.payAddress,
                    receiverAddress: orderDetails.receiverAddress,
                    feeRate: orderDetails.feeRate
                }}
            />
        </div>
    );
};

export default VideoCompressMagic;
