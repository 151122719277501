// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add custom font */

/* Global styles for the BalanceDisplay */
.balance-display-container {
  max-width: 600px; /* Adjust the width as needed */
  margin: 40px auto; /* Center the container */
  padding: 20px;
  background: linear-gradient(135deg, #1b1b1b, #383838); /* Dark gradient for 3D effect */
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 3D effect shadow */
  color: #ffffff; /* Light text color for readability */
  text-align: center; /* Center text */
  font-family: 'Orbitron', sans-serif; /* New font */
}

.balance-display-container h2 {
  font-size: 24px; /* Adjust as needed */
  margin-bottom: 20px;
  color: #9e12ef; /* Light purple color */
}

.balance-display-container p {
  font-size: 18px; /* Adjust as needed */
  margin: 10px 0;
  color: #ffffff; /* Ensure text is readable */
}

.balance-display-container .loading {
  font-size: 18px; /* Adjust as needed */
  color: #ffffff; /* Ensure text is readable */
}
`, "",{"version":3,"sources":["webpack://./src/components/BalanceDisplay.css"],"names":[],"mappings":"AAA4F,oBAAoB;;AAEhH,yCAAyC;AACzC;EACE,gBAAgB,EAAE,+BAA+B;EACjD,iBAAiB,EAAE,yBAAyB;EAC5C,aAAa;EACb,qDAAqD,EAAE,gCAAgC;EACvF,mBAAmB,EAAE,oBAAoB;EACzC,yCAAyC,EAAE,qBAAqB;EAChE,cAAc,EAAE,qCAAqC;EACrD,kBAAkB,EAAE,gBAAgB;EACpC,mCAAmC,EAAE,aAAa;AACpD;;AAEA;EACE,eAAe,EAAE,qBAAqB;EACtC,mBAAmB;EACnB,cAAc,EAAE,uBAAuB;AACzC;;AAEA;EACE,eAAe,EAAE,qBAAqB;EACtC,cAAc;EACd,cAAc,EAAE,4BAA4B;AAC9C;;AAEA;EACE,eAAe,EAAE,qBAAqB;EACtC,cAAc,EAAE,4BAA4B;AAC9C","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap'); /* Add custom font */\n\n/* Global styles for the BalanceDisplay */\n.balance-display-container {\n  max-width: 600px; /* Adjust the width as needed */\n  margin: 40px auto; /* Center the container */\n  padding: 20px;\n  background: linear-gradient(135deg, #1b1b1b, #383838); /* Dark gradient for 3D effect */\n  border-radius: 20px; /* Rounded corners */\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* 3D effect shadow */\n  color: #ffffff; /* Light text color for readability */\n  text-align: center; /* Center text */\n  font-family: 'Orbitron', sans-serif; /* New font */\n}\n\n.balance-display-container h2 {\n  font-size: 24px; /* Adjust as needed */\n  margin-bottom: 20px;\n  color: #9e12ef; /* Light purple color */\n}\n\n.balance-display-container p {\n  font-size: 18px; /* Adjust as needed */\n  margin: 10px 0;\n  color: #ffffff; /* Ensure text is readable */\n}\n\n.balance-display-container .loading {\n  font-size: 18px; /* Adjust as needed */\n  color: #ffffff; /* Ensure text is readable */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
