import React, { useRef, useState, useCallback, useEffect } from 'react';
import p5 from 'p5';
import axios from 'axios';
import { minify } from 'terser';
import * as fflate from 'fflate';
import './ImageCompressMagic.css';
import ConfirmPurchaseModal from './ConfirmPurchaseModal';

const BACKEND_URL = 'https://inscription-service-production.up.railway.app';

const SavingsModal = ({ isOpen, onClose, savings }) => {
    if (!isOpen) return null;
    return (
        <div className="modal">
            <div className="modal-content">
                <h4>Compression Savings</h4>
                <p>You saved {savings}% in file size by compressing your code.</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

const ImageCompressMagic = () => {
    const sketchRef = useRef();
    const previewRef = useRef();
    const [image, setImage] = useState(null);
    const [generatedCode, setGeneratedCode] = useState('');
    const [finalHtml, setFinalHtml] = useState('');
    const [orderDetails, setOrderDetails] = useState({
        totalAmount: 0,
        totalAmountBTC: '',
        payAddress: '',
        receiverAddress: '',
        devAddress: '',
        devFee: 0,
        feeRate: 10,
    });
    const [recommendedFees, setRecommendedFees] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [savingsModalOpen, setSavingsModalOpen] = useState(false);
    const [savings, setSavings] = useState(0);

    useEffect(() => {
        const fetchRecommendedFees = async () => {
            try {
                const response = await axios.get('https://mempool.space/api/v1/fees/recommended');
                setRecommendedFees(response.data);
            } catch (error) {
                console.error('Error fetching recommended fees:', error);
            }
        };
        fetchRecommendedFees();
    }, []);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const kmeans = (data, k) => {
        const centroids = data.slice(0, k);
        let clusters = new Array(data.length).fill(0);
        let newCentroids = Array.from({ length: k }, () => [0, 0, 0]);
        let changed = true;

        while (changed) {
            changed = false;

            for (let i = 0; i < data.length; i++) {
                const distances = centroids.map(centroid =>
                    Math.sqrt(data[i].reduce((sum, val, idx) => sum + Math.pow(val - centroid[idx], 2), 0))
                );
                const newCluster = distances.indexOf(Math.min(...distances));
                if (newCluster !== clusters[i]) {
                    clusters[i] = newCluster;
                    changed = true;
                }
            }

            newCentroids = Array.from({ length: k }, () => [0, 0, 0]);
            const counts = new Array(k).fill(0);
            for (let i = 0; i < data.length; i++) {
                newCentroids[clusters[i]] = newCentroids[clusters[i]].map((val, idx) => val + data[i][idx]);
                counts[clusters[i]]++;
            }
            for (let i = 0; i < k; i++) {
                if (counts[i] > 0) {
                    newCentroids[i] = newCentroids[i].map(val => Math.floor(val / counts[i]));
                }
            }
            centroids.forEach((_, idx) => centroids[idx] = newCentroids[idx]);
        }
        return { clusters, centroids };
    };

    const generateP5Code = useCallback(() => {
        if (image) {
            const hexToRgb = (hex) => {
                let r = parseInt(hex.substring(0, 2), 16);
                let g = parseInt(hex.substring(2, 4), 16);
                let b = parseInt(hex.substring(4, 6), 16);
                return [r, g, b];
            };

            const sketch = (p) => {
                let img;
                p.preload = () => {
                    img = p.loadImage(image);
                };

                p.setup = () => {
                    const targetSize = 100;
                    const colorClusters = 32;
                    p.createCanvas(targetSize, targetSize);
                    img.resize(targetSize, targetSize);
                    img.loadPixels();

                    let pixels = [];
                    for (let y = 0; y < img.height; y++) {
                        for (let x = 0; x < img.width; x++) {
                            let index = (x + y * img.width) * 4;
                            let r = img.pixels[index];
                            let g = img.pixels[index + 1];
                            let b = img.pixels[index + 2];
                            pixels.push([r, g, b]);
                        }
                    }

                    let { clusters, centroids } = kmeans(pixels, colorClusters);
                    let encodedPixels = clusters.map(cluster => {
                        let [r, g, b] = centroids[cluster];
                        return `${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
                    });

                    const encodedString = encodedPixels.join(';');
                    const code = `
let encodedPixels = "${encodedString}".split(';');

function hexToRgb(hex) {
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  return [r, g, b];
}

function setup() {
  createCanvas(windowWidth, windowHeight);
  let imgWidth = ${img.width};
  let imgHeight = ${img.height};
  let scaleWidth = width / imgWidth;
  let scaleHeight = height / imgHeight;
  noStroke();
  pixelDensity(1);

  for (let y = 0; y < imgHeight; y++) {
    for (let x = 0; x < imgWidth; x++) {
      let index = x + y * imgWidth;
      let colorHex = encodedPixels[index];
      let [r, g, b] = hexToRgb(colorHex);
      fill(r, g, b);
      rect(x * scaleWidth, y * scaleHeight, ceil(scaleWidth), ceil(scaleHeight));
    }
  }
}

function draw() {}
`;
                    setGeneratedCode(code);

                    const previewSketch = (p) => {
                        p.setup = () => {
                            p.createCanvas(p.windowWidth, p.windowHeight);
                            p.noLoop();
                        };

                        p.draw = () => {
                            let imgWidth = img.width;
                            let imgHeight = img.height;
                            let scaleWidth = p.width / imgWidth;
                            let scaleHeight = p.height / imgHeight;
                            p.noStroke();
                            p.pixelDensity(1);

                            for (let y = 0; y < imgHeight; y++) {
                                for (let x = 0; x < imgWidth; x++) {
                                    let index = x + y * imgWidth;
                                    let colorHex = encodedPixels[index];
                                    let [r, g, b] = hexToRgb(colorHex);
                                    p.fill(r, g, b);
                                    p.rect(x * scaleWidth, y * scaleHeight, Math.ceil(scaleWidth), Math.ceil(scaleHeight));
                                }
                            }
                        };
                    };

                    const p5PreviewInstance = new p5(previewSketch, previewRef.current);
                    return () => {
                        p5PreviewInstance.remove();
                    };
                };
            };

            const p5Instance = new p5(sketch, sketchRef.current);
            return () => {
                p5Instance.remove();
            };
        }
    }, [image]);

    const handleCompressAndGenerateHTML = async () => {
        try {
            const minifiedResult = await minify(generatedCode);
            if (minifiedResult.error) {
                console.error('Minification error:', minifiedResult.error);
                return;
            }
            const originalSize = new TextEncoder().encode(generatedCode).length;
            const compressed = fflate.gzipSync(new TextEncoder().encode(minifiedResult.code));
            const compressedSize = compressed.length;
            const base64Encoded = btoa(String.fromCharCode(...compressed));
            const htmlContent = `
        <script>
            import("/content/d795ba6cf2ea7d4ed9c159e498ba2c9ad4295d8ea257fb1ee88e9244c016adc2i0")
            .then(ob1 => ob1.ob1Scribe("${base64Encoded}"));
        </script>
            `;
            setFinalHtml(htmlContent);
            const savingsPercentage = ((originalSize - compressedSize) / originalSize * 100).toFixed(2);
            setSavings(savingsPercentage);
            setSavingsModalOpen(true);
        } catch (error) {
            console.error("Minification and compression error:", error);
        }
    };

    const handleReceiverAddressChange = (event) => {
        setOrderDetails({ ...orderDetails, receiverAddress: event.target.value });
    };

    const handleFeeRateChange = (event) => {
        setOrderDetails({ ...orderDetails, feeRate: event.target.value });
    };

    const handleSubmit = async () => {
        if (!finalHtml) {
            alert("Please generate the HTML first.");
            return;
        }

        const blob = new Blob([finalHtml], { type: 'text/html' });
        const formData = new FormData();
        formData.append("file", blob, 'compressedCode.html');

        try {
            const response = await fetch(`${BACKEND_URL}/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    'User-Selected-Fee-Rate': orderDetails.feeRate,
                    'User-Selected-Receiver-Address': orderDetails.receiverAddress,
                }
            });

            if (response.ok) {
                const result = await response.json();
                setOrderDetails({
                    ...orderDetails,
                    totalAmount: result.payAddressAmount,
                    totalAmountBTC: result.payAddressAmount / 100000000,
                    payAddress: result.payAddress,
                    receiverAddress: result.receiverAddress,
                    devAddress: result.devAddress,
                    devFee: result.devFee,
                    feeRate: result.feeRate
                });
                setIsModalOpen(true);
            } else {
                alert('Failed to upload HTML and create order. Please try again.');
            }
        } catch (error) {
            alert('An error occurred while processing your request.');
            console.error("Error during the fetch request:", error);
        }
    };

    const handleConfirm = async () => {
        if (window.unisat && window.unisat.sendBitcoin) {
            try {
                const txidPay = await window.unisat.sendBitcoin(orderDetails.payAddress, orderDetails.totalAmount, { feeRate: parseInt(orderDetails.feeRate) });
                console.log('Payment transaction successful. TXID:', txidPay);
                alert('Transaction successful! TXID: ' + txidPay);
                setIsModalOpen(false);
            } catch (error) {
                console.error('Transaction failed:', error);
                alert('Transaction failed. Please try again or check your wallet.');
            }
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="image-compress-magic-container">
            <input type="file" accept="image/*" onChange={handleImageUpload} />
            <button onClick={generateP5Code}>Generate p5.js Code</button>
            <button onClick={handleCompressAndGenerateHTML}>Compress & Generate HTML</button>
            <SavingsModal isOpen={savingsModalOpen} onClose={() => setSavingsModalOpen(false)} savings={savings} />
            <div ref={sketchRef} className="image-compress-magic"></div>
            <div ref={previewRef} className="image-compress-magic-preview"></div> {/* This is the preview container */}
            <div>
                <label>Receiver Address:</label>
                <input type="text" value={orderDetails.receiverAddress} onChange={handleReceiverAddressChange} placeholder="Enter receiver address" />
            </div>
            <div>
                <label>Fee Rate (sat/vB):</label>
                <input type="number" value={orderDetails.feeRate} onChange={handleFeeRateChange} min="1" />
                {recommendedFees.fastestFee && (
                    <div>
                        <p>Fastest Fee: {recommendedFees.fastestFee} sat/vB</p>
                        <p>Half Hour Fee: {recommendedFees.halfHourFee} sat/vB</p>
                        <p>Hour Fee: {recommendedFees.hourFee} sat/vB</p>
                    </div>
                )}
            </div>
            <button onClick={handleSubmit} disabled={!finalHtml}>Inscribe Compressed Code</button>
            <ConfirmPurchaseModal
                isOpen={isModalOpen}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                paymentDetails={{
                    payAddressAmount: orderDetails.totalAmount,
                    payAddress: orderDetails.payAddress,
                    receiverAddress: orderDetails.receiverAddress,
                    feeRate: orderDetails.feeRate
                }}
            />
        </div>
    );
};

export default ImageCompressMagic;
