// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ImageCompressMagic.css */
.image-compress-magic-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .image-compress-magic-preview {
    width: 300px;  
    height: 300px; 
    overflow: hidden; 
    margin: 0 auto; 
    border: 1px solid #ccc; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-compress-magic-preview canvas {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
  
  .image-compress-magic {
    margin-top: 20px;
  }
  
  .generated-code {
    margin-top: 20px;
    max-width: 100%;
    word-wrap: break-word;
  }
  
  .generated-code pre {
    background: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto;
    white-space: pre-wrap;
  }
  
  .generated-code button {
    margin-bottom: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .generated-code button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ImageCompressMagic.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;EACA;IACE,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,2CAA2C;IAC3C,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,qBAAqB;EACvB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;EACvB;;EAEA;IACE,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* src/components/ImageCompressMagic.css */\n.image-compress-magic-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  .image-compress-magic-preview {\n    width: 300px;  \n    height: 300px; \n    overflow: hidden; \n    margin: 0 auto; \n    border: 1px solid #ccc; \n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); \n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .image-compress-magic-preview canvas {\n    max-width: 100%;\n    max-height: 100%;\n    width: auto;\n    height: auto;\n  }\n  \n  .image-compress-magic {\n    margin-top: 20px;\n  }\n  \n  .generated-code {\n    margin-top: 20px;\n    max-width: 100%;\n    word-wrap: break-word;\n  }\n  \n  .generated-code pre {\n    background: #f0f0f0;\n    padding: 10px;\n    border-radius: 5px;\n    overflow-x: auto;\n    white-space: pre-wrap;\n  }\n  \n  .generated-code button {\n    margin-bottom: 10px;\n    padding: 5px 10px;\n    border: none;\n    background-color: #007bff;\n    color: white;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .generated-code button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
