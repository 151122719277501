import React, { useState, useEffect } from 'react';
import { getInscriptions } from '../api/walletApi';
import './TransactionHistory.css';

const TransactionHistory = () => {
    const [history, setHistory] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [inputItemsPerPage, setInputItemsPerPage] = useState(20);

    useEffect(() => {
        fetchHistory(itemsPerPage);
    }, [itemsPerPage]);

    const fetchHistory = async (items) => {
        try {
            const fetchedHistory = await getInscriptions(0, items);
            setHistory(Array.isArray(fetchedHistory) ? fetchedHistory : []);
        } catch (e) {
            console.error('Error fetching transaction history:', e);
            setHistory([]);
        }
    };

    const handleItemsPerPageChange = (e) => {
        setInputItemsPerPage(Number(e.target.value));
    };

    const handleSubmit = () => {
        setItemsPerPage(inputItemsPerPage);
    };

    const handleClick = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className="transaction-history">
            <h2>Transaction History</h2>
            <div className="transactions-control">
                <label htmlFor="itemsPerPage">Transactions per page:</label>
                <input
                    type="number"
                    id="itemsPerPage"
                    value={inputItemsPerPage}
                    onChange={handleItemsPerPageChange}
                    min="1"
                    max="100"
                />
                <button onClick={handleSubmit}>Update</button>
            </div>
            {history.length > 0 ? (
                <div className="transaction-list">
                    {history.map((transaction, index) => (
                        <div key={transaction.inscriptionId || index} className="transaction-item" onClick={() => handleClick(transaction.content)}>
                            <p>Inscription ID: {transaction.inscriptionId}</p>
                            <p>Address: {transaction.address}</p>
                            <p>Output Value: {transaction.outputValue}</p>
                            {transaction.content && (
                                <div className="content-container">
                                    <iframe
                                        src={transaction.content}
                                        title="Transaction Content"
                                        sandbox="allow-scripts allow-same-origin"
                                        frameBorder="0"
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <p>No transactions found.</p>
            )}
        </div>
    );
};

export default TransactionHistory;
