import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { minify } from 'terser';
import * as fflate from 'fflate';
import './P5jsCodeCompressMagic.css';
import ConfirmPurchaseModal from './ConfirmPurchaseModal';

const BACKEND_URL = 'https://inscription-service-production.up.railway.app';

const SavingsModal = ({ isOpen, onClose, savings }) => {
    if (!isOpen) return null;
    return (
        <div className="modal">
            <div className="modal-content">
                <h4>Compression Savings</h4>
                <p>You saved {savings}% in file size by compressing your code.</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

const ScriptLoaderAndCompressor = () => {
    const [inputCode, setInputCode] = useState('');
    const [finalHtml, setFinalHtml] = useState('');
    const [orderDetails, setOrderDetails] = useState({
        totalAmount: 0,
        totalAmountBTC: '',
        payAddress: '',
        receiverAddress: '',
        devAddress: '',
        devFee: 0,
        feeRate: 10,
    });
    const [recommendedFees, setRecommendedFees] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [savingsModalOpen, setSavingsModalOpen] = useState(false);
    const [savings, setSavings] = useState(0);
    const iframeRef = useRef(null);
    const [iframeKey, setIframeKey] = useState(0);

    useEffect(() => {
        const fetchRecommendedFees = async () => {
            try {
                const response = await axios.get('https://mempool.space/api/v1/fees/recommended');
                setRecommendedFees(response.data);
            } catch (error) {
                console.error('Error fetching recommended fees:', error);
            }
        };
        fetchRecommendedFees();
    }, []);

    const processCode = useCallback((code) => {
        return code.replace(/['"]\/content\/(.*?)['"]/g, '"https://ordinals.com/content/$1"');
    }, []);

    const displaySketch = useCallback(() => {
        if (!inputCode.trim()) return;
        const processedCode = processCode(inputCode);
        const fullHTML = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <script src="https://ordinals.com/content/7e37766541506810ba6399c4b2735121f508bd9209df43dd200bf2316b014594i0"></script>
                <style>
                    html, body { margin: 0; padding: 0; }
                    canvas { position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); }
                </style>
            </head>
            <body>
                <script>
                    ${processedCode}
                </script>
            </body>
            </html>
        `;

        const iframeDoc = iframeRef.current.contentDocument;
        iframeDoc.open();
        iframeDoc.write(fullHTML);
        iframeDoc.close();
    }, [inputCode, processCode]);

    useEffect(() => {
        displaySketch();
    }, [iframeKey, displaySketch]);

    const handleCodeChange = (event) => {
        setInputCode(event.target.value);
        setIframeKey(prevKey => prevKey + 1);
    };

    const handleCompressAndGenerateHTML = async () => {
        try {
            const minifiedResult = await minify(inputCode);
            if (minifiedResult.error) {
                console.error('Minification error:', minifiedResult.error);
                return;
            }
            const originalSize = new TextEncoder().encode(inputCode).length;
            const compressed = fflate.gzipSync(new TextEncoder().encode(minifiedResult.code));
            const compressedSize = compressed.length;
            const base64Encoded = btoa(String.fromCharCode(...compressed));
            const htmlContent = `
    <script>
        import("/content/d795ba6cf2ea7d4ed9c159e498ba2c9ad4295d8ea257fb1ee88e9244c016adc2i0")
        .then(ob1 => ob1.ob1Scribe("${base64Encoded}"));
    </script>
`;

            setFinalHtml(htmlContent);
            const savingsPercentage = ((originalSize - compressedSize) / originalSize * 100).toFixed(2);
            setSavings(savingsPercentage);
            setSavingsModalOpen(true);
        } catch (error) {
            console.error("Minification and compression error:", error);
        }
    };

    const handleCloseSavingsModal = () => {
        setSavingsModalOpen(false);
    };

    const handleReceiverAddressChange = (event) => {
        setOrderDetails({...orderDetails, receiverAddress: event.target.value});
    };

    const handleFeeRateChange = (event) => {
        setOrderDetails({...orderDetails, feeRate: event.target.value});
    };

    const handleSubmit = async () => {
        if (!finalHtml) {
            alert("Please generate the HTML first.");
            return;
        }

        const blob = new Blob([finalHtml], { type: 'text/html' });
        const formData = new FormData();
        formData.append("file", blob, 'compressedCode.html');

        

        try {
            const response = await fetch(`${BACKEND_URL}/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    
                    'User-Selected-Fee-Rate': orderDetails.feeRate,
                    'User-Selected-Receiver-Address': orderDetails.receiverAddress,
                }
            });

            if (response.ok) {
                const result = await response.json();
                setOrderDetails({
                    ...orderDetails,
                    totalAmount: result.payAddressAmount,
                    totalAmountBTC: result.payAddressAmount / 100000000,
                    payAddress: result.payAddress,
                    receiverAddress: result.receiverAddress,
                    devAddress: result.devAddress,
                    devFee: result.devFee,
                    feeRate: result.feeRate
                });
                setIsModalOpen(true);
            } else {
                alert('Failed to upload HTML and create order. Please try again.');
            }
        } catch (error) {
            alert('An error occurred while processing your request.');
            console.error("Error during the fetch request:", error);
        }
    };

    const handleConfirm = async () => {
        if (window.unisat && window.unisat.sendBitcoin) {
            try {
                const txidPay = await window.unisat.sendBitcoin(orderDetails.payAddress, orderDetails.totalAmount, { feeRate: parseInt(orderDetails.feeRate) });
                console.log('Payment transaction successful. TXID:', txidPay);
                alert('Transaction successful! TXID: ' + txidPay);
                setIsModalOpen(false);
            } catch (error) {
                console.error('Transaction failed:', error);
                alert('Transaction failed. Please try again or check your wallet.');
            }
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
      
            <div className="container mx-auto text-center">
                <h1 className="title text-xl font-bold mb-1">P5.js Code Compressor and HTML Generator</h1>
                <p className="text-xs mb-4">Powered by Boppleton Library</p>
            <textarea className="textarea" value={inputCode} onChange={handleCodeChange} placeholder="Enter your p5.js code here..."></textarea>
            <button className="button" onClick={handleCompressAndGenerateHTML}>Compress & Generate HTML</button>
            <SavingsModal isOpen={savingsModalOpen} onClose={handleCloseSavingsModal} savings={savings} />
            <div className="iframe-container">
                <h3>Ordinal Content Viewer:</h3>
                <iframe
                    key={iframeKey}
                    ref={iframeRef}
                    style={{ width: '100%', height: '500px', border: '1px solid black' }}
                    title="p5.js Sketch Output"
                />
            </div>
            <div>
                <label>Receiver Address:</label>
                <input type="text" value={orderDetails.receiverAddress} onChange={handleReceiverAddressChange} placeholder="Enter receiver address" />
            </div>
            <div>
                <label>Fee Rate (sat/vB):</label>
                <input type="number" value={orderDetails.feeRate} onChange={handleFeeRateChange} min="1" />
                {recommendedFees.fastestFee && (
                    <div>
                        <p>Fastest Fee: {recommendedFees.fastestFee} sat/vB</p>
                        <p>Half Hour Fee: {recommendedFees.halfHourFee} sat/vB</p>
                        <p>Hour Fee: {recommendedFees.hourFee} sat/vB</p>
                    </div>
                )}
            </div>
            <button className="button" onClick={handleSubmit} disabled={!finalHtml}>Inscribe Compressed Code</button>
            <ConfirmPurchaseModal
                isOpen={isModalOpen}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                paymentDetails={{
                    payAddressAmount: orderDetails.totalAmount,
                    payAddress: orderDetails.payAddress,
                    receiverAddress: orderDetails.receiverAddress,
                    feeRate: orderDetails.feeRate
                }}
            />
        </div>
    );
};

export default ScriptLoaderAndCompressor;
