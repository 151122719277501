import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Orders.css'; 

const API_BASE_URL = "https://exhibition-service-production.up.railway.app"

const OrderDashboard = () => {
  const [apiKey, setApiKey] = useState('');
  const [status, setStatus] = useState('pending');
  const [sortOrder, setSortOrder] = useState('asc');
  const [itemCount, setItemCount] = useState(16);
  const [receiveAddress, setReceiveAddress] = useState('');
  const [clientId, setClientId] = useState('');
  const [cursor, setCursor] = useState(0); 
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState('');
  const [pendingCollections, setPendingCollections] = useState([]);
  const [approvedCollections, setApprovedCollections] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [editingCollection, setEditingCollection] = useState(null); 

  useEffect(() => {
    if (isAuthenticated) {
      fetchPendingCollections();
      fetchApprovedCollections();
    }
  }, [isAuthenticated]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_BASE_URL}/api/login`, { username, password });

      if (response.data.success) {
        setIsAuthenticated(true);
      } else {
        alert('Invalid credentials. Please try again.');
      }
    } catch (err) {
      console.error('Login failed:', err.message);
      alert('An error occurred during login. Please try again.');
    }
  };

  const fetchOrders = async () => {
    setError('');
    if (!apiKey) {
      setError('Please enter a valid API key.');
      return;
    }

    const encodedReceiveAddress = encodeURIComponent(receiveAddress.trim());

    const params = new URLSearchParams({
      status,
      sort: sortOrder,
      size: itemCount,
      ...(receiveAddress && { receiveAddress: encodedReceiveAddress }),
      ...(clientId && { clientId }),
      cursor,
    });

    try {
      const response = await axios.get(`https://open-api.unisat.io/v2/inscribe/order/list?${params.toString()}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
      });

      if (response.data && response.data.code === 0) {
        const fetchedOrders = response.data.data.list.map(order => ({
          ...order,
          formattedDate: new Date(order.timestamp).toLocaleString(), // Format the date and time
        }));
        setOrders(fetchedOrders);
      } else {
        setError('Invalid or unexpected API response structure.');
      }
    } catch (err) {
      setError(`Failed to fetch orders: ${err.message || err.response?.data?.msg}`);
      setOrders([]);
    }
  };

  const fetchPendingCollections = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/pending-collections`, {
        auth: {
          username: username,
          password: password,
        },
      });
      setPendingCollections(response.data);
    } catch (err) {
      setError(`Failed to fetch pending collections: ${err.message}`);
    }
  };

  const fetchApprovedCollections = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/artworks`, {
        auth: {
          username: username,
          password: password,
        },
      });
      setApprovedCollections(response.data);
    } catch (err) {
      setError(`Failed to fetch approved collections: ${err.message}`);
    }
  };

  const approveCollection = async (collection) => {
    try {
      await axios.post(`${API_BASE_URL}/api/approve-collection`, { title: collection.title }, {
        auth: {
          username: username,
          password: password,
        },
      });
      alert(`Collection "${collection.title}" has been approved.`);
      setPendingCollections(prev => prev.filter(item => item.title !== collection.title));
      fetchApprovedCollections();
    } catch (err) {
      setError(`Failed to approve collection: ${err.message}`);
    }
  };

  const handleEditCollection = (collection, isApproved) => {
    setEditingCollection({ ...collection, isApproved });
  };

  const handleSaveCollection = async () => {
    const { id, isApproved, ...updatedData } = editingCollection;
    try {
      await axios.put(`${API_BASE_URL}/api/artworks/${id}`, updatedData, {
        auth: {
          username: username,
          password: password,
        },
      });
      alert('Collection updated successfully.');
      setEditingCollection(null);
      if (isApproved) {
        fetchApprovedCollections();
      } else {
        fetchPendingCollections();
      }
    } catch (err) {
      setError(`Failed to update collection: ${err.message}`);
    }
  };

  const updateSupplyAndSold = async (collectionId, newItems, newSold) => {
    try {
      await axios.post(`${API_BASE_URL}/api/artworks/update-supply`, { artworkId: collectionId, items: newItems, sold: newSold }, {
        auth: {
          username: username,
          password: password,
        },
      });
      alert('Supply and sold amount updated successfully.');
      fetchApprovedCollections(); // Refresh the collection data
    } catch (err) {
      setError(`Failed to update supply and sold amount: ${err.message}`);
    }
  };

  const deleteCollection = async (collectionId, isApproved) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/artworks/${collectionId}`, {
        auth: {
          username: username,
          password: password,
        },
      });
      alert('Collection has been deleted.');
      if (isApproved) {
        setApprovedCollections(prev => prev.filter(item => item.id !== collectionId));
      } else {
        setPendingCollections(prev => prev.filter(item => item.id !== collectionId));
      }
    } catch (err) {
      setError(`Failed to delete collection: ${err.message}`);
    }
  };

  const fetchOrderDetails = async (orderId) => {
    setError('');
    try {
      const response = await axios.get(`https://open-api.unisat.io/v2/inscribe/order/${orderId}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
      });

      if (response.data && response.data.code === 0) {
        const orderDetails = {
          ...response.data.data,
          formattedDate: new Date(response.data.data.timestamp).toLocaleString(), // Format the date and time
        };
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.orderId === orderId ? { ...order, details: orderDetails } : order
          )
        );
      } else {
        setError('Invalid or unexpected API response structure.');
      }
    } catch (err) {
      setError(`Failed to fetch order details: ${err.message || err.response?.data?.msg}`);
    }
  };

  return (
    <div className="order-dashboard">
      {!isAuthenticated ? (
        <form onSubmit={handleLogin} className="login-form">
          <h2>Login</h2>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <button type="submit">Login</button>
        </form>
      ) : (
        <>
          <h1>Order Dashboard</h1>
          <input
            type="text"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            placeholder="Enter API Key"
          />
          <input
            type="text"
            value={receiveAddress}
            onChange={(e) => setReceiveAddress(e.target.value)}
            placeholder="Filter by Receive Address (optional)"
          />
          <input
            type="text"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
            placeholder="Filter by Client ID (optional)"
          />
          <select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="pending">Pending</option>
            <option value="inscribing">Inscribing</option>
            <option value="minted">Minted</option>
            <option value="closed">Closed</option>
            <option value="refunded">Refunded</option>
          </select>
          <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
            <option value="asc">Ascending</option>
            <option value="desc">Descending</option>
          </select>
          <input
            type="number"
            value={itemCount}
            onChange={(e) => setItemCount(Number(e.target.value))}
            placeholder="Number of items"
          />
          <input
            type="number"
            value={cursor}
            onChange={(e) => setCursor(Number(e.target.value))}
            placeholder="Start offset"
          />
          <button onClick={fetchOrders}>Fetch Orders</button>
          {error && <p className="error">{error}</p>}
          <div>
            {orders.length > 0 ? (
              orders.map((order, index) => (
                <div key={order.orderId || index} className="order-item">
                  <p>Order ID: {order.orderId}</p>
                  <p>Status: {order.status}</p>
                  <p>Date: {order.formattedDate}</p>
                  <p>Pay Address: {order.payAddress}</p>
                  <p>Receive Address: {order.receiveAddress}</p>
                  <p>Amount: {order.amount}</p>
                  <p>Paid Amount: {order.paidAmount}</p>
                  <p>Output Value: {order.outputValue}</p>
                  <p>Fee Rate: {order.feeRate}</p>
                  <p>Miner Fee: {order.minerFee}</p>
                  <p>Service Fee: {order.serviceFee}</p>
                  <p>Developer Fee: {order.devFee}</p>
                  {order.files && order.files.map((file, fileIndex) => (
                    <div key={fileIndex}>
                      <p>File: {file.filename}</p>
                      <p>Inscription ID: {file.inscriptionId}</p>
                      <p>File Status: {file.status}</p>
                    </div>
                  ))}
                  <p>Refund Transaction ID: {order.refundTxid}</p>
                  <p>Refund Amount: {order.refundAmount}</p>
                  <p>Refund Fee Rate: {order.refundFeeRate}</p>
                  <button onClick={() => fetchOrderDetails(order.orderId)}>Fetch Details</button>
                  {order.details && (
                    <div>
                      {order.details.files && order.details.files.map((file, fileIndex) => (
                        <div key={fileIndex}>
                          <p>File Name: {file.filename}</p>
                          <p>Inscription ID: {file.inscriptionId}</p>
                          <p>File Status: {file.status}</p>
                        </div>
                      ))}
                      <p>Receiver Address: {order.details.receiveAddress}</p>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No orders to display.</p>
            )}
          </div>

          {/* Pending Collections Management */}
          <div className="pending-collections">
            <h2>Pending Collections</h2>
            {pendingCollections.length > 0 ? (
              pendingCollections.map((collection, index) => (
                <div key={index} className="order-item">
                  {editingCollection && editingCollection.id === collection.id ? (
                    <div>
                      <input
                        type="text"
                        value={editingCollection.title}
                        onChange={(e) =>
                          setEditingCollection({ ...editingCollection, title: e.target.value })
                        }
                        placeholder="Title"
                      />
                      <input
                        type="text"
                        value={editingCollection.description}
                        onChange={(e) =>
                          setEditingCollection({ ...editingCollection, description: e.target.value })
                        }
                        placeholder="Description"
                      />
                      <input
                        type="text"
                        value={editingCollection.price}
                        onChange={(e) =>
                          setEditingCollection({ ...editingCollection, price: e.target.value })
                        }
                        placeholder="Price"
                      />
                      <input
                        type="text"
                        value={editingCollection.artist}
                        onChange={(e) =>
                          setEditingCollection({ ...editingCollection, artist: e.target.value })
                        }
                        placeholder="Artist"
                      />
                      <input
                        type="text"
                        value={editingCollection.iframeSrc}
                        onChange={(e) =>
                          setEditingCollection({ ...editingCollection, iframeSrc: e.target.value })
                        }
                        placeholder="Iframe Source"
                      />
                      <input
                        type="text"
                        value={editingCollection.sellerAddress}
                        onChange={(e) =>
                          setEditingCollection({ ...editingCollection, sellerAddress: e.target.value })
                        }
                        placeholder="Seller Address"
                      />
                      {/* Add more fields as needed */}
                      <button onClick={handleSaveCollection}>Save</button>
                      <button onClick={() => setEditingCollection(null)}>Cancel</button>
                    </div>
                  ) : (
                    <div>
                      <p>Title: {collection.title}</p>
                      <p>Description: {collection.description}</p>
                      <p>Price: {collection.price}</p>
                      <p>Artist: {collection.artist}</p>
                      <p>Iframe Source: {collection.iframeSrc}</p>
                      <p>Seller Address: {collection.sellerAddress}</p>
                      <button onClick={() => approveCollection(collection)}>Approve</button>
                      <button onClick={() => handleEditCollection(collection, false)}>Edit</button>
                      <button onClick={() => deleteCollection(collection.id, false)}>Delete</button>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No collections waiting for approval.</p>
            )}
          </div>

          {/* Approved Collections Management */}
          <div className="approved-collections">
            <h2>Approved Collections</h2>
            {approvedCollections.length > 0 ? (
              approvedCollections.map((collection, index) => (
                <div key={index} className="order-item">
                  {editingCollection && editingCollection.id === collection.id ? (
                    <div>
                      <input
                        type="text"
                        value={editingCollection.title}
                        onChange={(e) =>
                          setEditingCollection({ ...editingCollection, title: e.target.value })
                        }
                        placeholder="Title"
                      />
                      <input
                        type="text"
                        value={editingCollection.description}
                        onChange={(e) =>
                          setEditingCollection({ ...editingCollection, description: e.target.value })
                        }
                        placeholder="Description"
                      />
                      <input
                        type="text"
                        value={editingCollection.price}
                        onChange={(e) =>
                          setEditingCollection({ ...editingCollection, price: e.target.value })
                        }
                        placeholder="Price"
                      />
                      <input
                        type="text"
                        value={editingCollection.artist}
                        onChange={(e) =>
                          setEditingCollection({ ...editingCollection, artist: e.target.value })
                        }
                        placeholder="Artist"
                      />
                      <input
                        type="text"
                        value={editingCollection.iframeSrc}
                        onChange={(e) =>
                          setEditingCollection({ ...editingCollection, iframeSrc: e.target.value })
                        }
                        placeholder="Iframe Source"
                      />
                      <input
                        type="text"
                        value={editingCollection.sellerAddress}
                        onChange={(e) =>
                          setEditingCollection({ ...editingCollection, sellerAddress: e.target.value })
                        }
                        placeholder="Seller Address"
                      />
                      {/* Input for items (supply) and sold */}
                      <input
                        type="number"
                        value={editingCollection.items}
                        onChange={(e) =>
                          setEditingCollection({ ...editingCollection, items: e.target.value })
                        }
                        placeholder="Supply"
                      />
                      <input
                        type="number"
                        value={editingCollection.sold}
                        onChange={(e) =>
                          setEditingCollection({ ...editingCollection, sold: e.target.value })
                        }
                        placeholder="Sold"
                      />
                      <button onClick={() => updateSupplyAndSold(collection.id, editingCollection.items, editingCollection.sold)}>Update Supply and Sold</button>
                      <button onClick={handleSaveCollection}>Save</button>
                      <button onClick={() => setEditingCollection(null)}>Cancel</button>
                    </div>
                  ) : (
                    <div>
                      <p>Title: {collection.title}</p>
                      <p>Description: {collection.description}</p>
                      <p>Price: {collection.price}</p>
                      <p>Artist: {collection.artist}</p>
                      <p>Iframe Source: {collection.iframeSrc}</p>
                      <p>Seller Address: {collection.sellerAddress}</p>
                      <p>Supply: {collection.items}</p>
                      <p>Sold: {collection.sold}</p>
                      <button onClick={() => handleEditCollection(collection, true)}>Edit</button>
                      <button onClick={() => deleteCollection(collection.id, true)}>Delete</button>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No approved collections available.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default OrderDashboard;
