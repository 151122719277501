import React from "react";
import Sketch from "react-p5";

const PillAnimation = () => {
    
    let pills = [
        { x: 150, y: 150, z: 0, xSpeed: 0.5, ySpeed: 0.6, zSpeed: 0.4 },
        { x: -150, y: -150, z: 50, xSpeed: 0.4, ySpeed: 0.5, zSpeed: 0.3 },
        { x: 100, y: -100, z: -50, xSpeed: 0.3, ySpeed: 0.4, zSpeed: 0.2 },
        { x: -100, y: 100, z: 100, xSpeed: 0.2, ySpeed: 0.3, zSpeed: 0.1 }
    ];

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(p5.windowWidth, p5.windowHeight, p5.WEBGL).parent(canvasParentRef);
        p5.noStroke(); 
    };

    const draw = (p5) => {
        p5.clear(); 
        p5.rotateX(p5.frameCount * 0.005); 
        p5.rotateY(p5.frameCount * 0.005); 

        pills.forEach(pill => {
            
            pill.x += pill.xSpeed;
            pill.y += pill.ySpeed;
            pill.z += pill.zSpeed;

            
            if (pill.x > p5.width / 2 || pill.x < -p5.width / 2) pill.xSpeed *= -1;
            if (pill.y > p5.height / 2 || pill.y < -p5.height / 2) pill.ySpeed *= -1;
            if (pill.z > 200 || pill.z < -200) pill.zSpeed *= -1;

            
            drawPill(p5, pill.x, pill.y, pill.z);
        });
    };

    const drawPill = (p5, x, y, z) => {
        
        p5.pointLight(250, 250, 250, 100, 100, 100);

        
        p5.push();
        p5.fill(255, 165, 0); 
        p5.translate(x, y + 25, z); 
        p5.cylinder(40, 50);
        p5.pop();

        
        p5.push();
        p5.fill(255, 0, 0); 
        p5.translate(x, y - 25, z); 
        p5.cylinder(40, 50);
        p5.pop();

        
        p5.push();
        p5.fill(255, 165, 0); 
        p5.translate(x, y + 50, z); 
        p5.rotateX(p5.HALF_PI);
        p5.ellipsoid(40, 40, 40);
        p5.pop();

        
        p5.push();
        p5.fill(255, 0, 0); 
        p5.translate(x, y - 50, z); 
        p5.rotateX(p5.HALF_PI);
        p5.ellipsoid(40, 40, 40);
        p5.pop();
    };

    return (
        <div className="pill-animation-container">
            <Sketch setup={setup} draw={draw} />
        </div>
    );
};

export default PillAnimation;
