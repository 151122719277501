import React, { useState, useEffect } from 'react';
import axios from 'axios';
import WalletConnector from './WalletConnector';
import PurchaseSuccessModal from './PurchaseSuccessModal';
import './Gallery.css';
import ConfirmPurchaseModal from './ConfirmPurchaseModal'; 

const API_BASE_URL = 'https://gallery-service-production.up.railway.app';
const Gallery = () => {
    const [artworks, setArtworks] = useState([]);
    const [selectedArtwork, setSelectedArtwork] = useState(null);
    const [walletAddress, setWalletAddress] = useState('');
    const [userSelectedFeeRate, setUserSelectedFeeRate] = useState(10);
    const [recommendedFees, setRecommendedFees] = useState({});
    const [isConfirming, setIsConfirming] = useState(false);
    const [isTransactionInProgress, setIsTransactionInProgress] = useState(false);
    const [selectedArtist, setSelectedArtist] = useState('all');
    const [artists, setArtists] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [transactionId, setTransactionId] = useState('');
    const [showCustomConfirmModal, setShowCustomConfirmModal] = useState(false);
    const [confirmationDetails, setConfirmationDetails] = useState({});

    useEffect(() => {
        const fetchArtworksAndArtists = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/artworks`);
                setArtworks(response.data);
                const uniqueArtists = [...new Set(response.data.map(item => item.artist))];
                setArtists(uniqueArtists);
            } catch (error) {
                console.error("Error fetching artworks:", error);
            }

            try {
                const feesResponse = await axios.get('https://mempool.space/api/v1/fees/recommended');
                setRecommendedFees(feesResponse.data);
            } catch (error) {
                console.error('Error fetching recommended fees:', error);
            }
        };

        fetchArtworksAndArtists();
    }, []);

    const handleBuy = artwork => {
        if (!isTransactionInProgress) {
            setSelectedArtwork(artwork);
            setIsConfirming(true);
        }
    };

    const handleConfirmPurchase = async () => {
        if (isTransactionInProgress) {
            console.log('Transaction already in progress - Ignoring additional request');
            return;
        }
    
        if (!selectedArtwork || !walletAddress) {
            alert('Please connect your wallet and select an artwork.');
            return;
        }
    
        setIsTransactionInProgress(true);
        console.log('Transaction started');
        const feeRate = parseInt(userSelectedFeeRate, 10);
    
        try {
            const purchaseData = {
                artworkId: selectedArtwork.id,
                userSelectedFeeRate: feeRate,
                userSelectedReceiverAddress: walletAddress,
            };
    
            console.log('Sending purchase data to server', purchaseData);
            const response = await axios.post(`${API_BASE_URL}/api/purchase`, purchaseData);
            console.log('Purchase data response', response.data);
            const result = response.data;
    
            setConfirmationDetails({
                payAddressAmount: result.payAddressAmount,
                payAddress: result.payAddress,
                receiverAddress: result.receiverAddress,
                feeRate: userSelectedFeeRate,
            });
            setShowCustomConfirmModal(true);
        } catch (error) {
            console.error("Error during the purchase request:", error);
            alert('An error occurred while processing your purchase.');
        } finally {
            setIsTransactionInProgress(false);
            setIsConfirming(false);
        }
    };

    const handlePasteAddress = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setWalletAddress(text);
        } catch (error) {
            console.error('Failed to read clipboard contents:', error);
        }
    };

    const handleTransactionConfirmation = async () => {
        setShowCustomConfirmModal(false);
    
        if (window.unisat) {
            try {
                console.log('Sending payment via UniSat for the inscription fee');
                const txId = await window.unisat.sendBitcoin(
                    confirmationDetails.payAddress, 
                    confirmationDetails.payAddressAmount, 
                    { feeRate: confirmationDetails.feeRate }
                );
                console.log('Inscription fee transaction completed', txId);
    
                if (txId) {
                    console.log('Transaction ID:', txId);
                    setTransactionId(txId);

                    
                    const newSoldCount = selectedArtwork.sold + 1;

                    
                    await axios.post(`${API_BASE_URL}/api/artworks/update-supply`, {
                        artworkId: selectedArtwork.id,
                        sold: newSoldCount
                    });

                    
                    setArtworks(artworks.map(art => {
                        if (art.id === selectedArtwork.id) {
                            return { ...art, sold: newSoldCount };
                        }
                        return art;
                    }));

                    setShowSuccessModal(true);
                } else {
                    console.log('No transaction ID returned from payment');
                }
            } catch (error) {
                console.error("Error during the payment transaction:", error);
                alert('An error occurred while processing your payment.');
            }
        }
    };
    return (
        <div className="gallery-gallery-container">
          <WalletConnector onConnected={setWalletAddress} />
          <select value={selectedArtist} onChange={(e) => setSelectedArtist(e.target.value)} className="artist-dropdown">
            <option value="all">All Artists</option>
            {artists.map((artist, index) => (
              <option key={index} value={artist}>{artist}</option>
            ))}
          </select>
          {artworks.filter(artwork => selectedArtist === 'all' || artwork.artist === selectedArtist).map((artwork) => (
            <div key={artwork.id} className={`gallery-gallery-art-item ${isConfirming && selectedArtwork && selectedArtwork.id === artwork.id ? 'dimmed-artwork' : ''}`}>
              <div className="gallery-gallery-iframe-wrapper">
                <iframe src={artwork.iframeSrc} title={artwork.title} frameBorder="0" scrolling="no" className="gallery-gallery-artwork-iframe" allowFullScreen></iframe>
              </div>
              <div className="gallery-art-text-container">
                <h3 className="gallery-art-title">{artwork.title}</h3>
                <div className="gallery-tooltip">Hover to read
                  <span className="gallery-tooltip-text">{artwork.description}</span>
                </div>
                <p className="gallery-art-supply">
                  {artwork.items - artwork.sold > 0 ? `Supply: ${artwork.items - artwork.sold} / ${artwork.items}` : 'Sold Out'}
                </p>
                <p className="gallery-art-price">Price: {artwork.price}</p>
                <button onClick={() => handleBuy(artwork)} disabled={isTransactionInProgress || isConfirming || artwork.items - artwork.sold <= 0} className="gallery-buy-button">
                  {artwork.items - artwork.sold > 0 ? 'Buy' : 'Sold Out'}
                </button>
              </div>
              {isConfirming && selectedArtwork && selectedArtwork.id === artwork.id && (
                <div className="gallery-purchase-confirmation">
                  <h2 className="gallery-confirm-title">Confirm Purchase</h2>
                  <p className="gallery-confirm-description">You are about to purchase: {selectedArtwork.title}</p>
                  <p className="gallery-confirm-total-fee">Total Fee: {selectedArtwork.price}</p>
                  <p className="gallery-confirm-fee-rate">Fee Rate: {userSelectedFeeRate} sat/vB</p>
                  <div>
                    <label className="gallery-confirm-label">Receiver Address:</label>
                    <input type="text" value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)} className="gallery-input-field" />
                    <button onClick={handlePasteAddress} className="gallery-paste-button">Paste Address</button>
                  </div>
                  <div>
                    <label className="gallery-confirm-label">Fee Rate (sat/vB):</label>
                    <input type="number" value={userSelectedFeeRate} onChange={(e) => setUserSelectedFeeRate(e.target.value)} min="1" className="gallery-input-field" />
                    {recommendedFees.fastestFee && (
                      <div className="gallery-fees-info">
                        <p className="gallery-fees-fastest">Fastest Fee: {recommendedFees.fastestFee} sat/vB</p>
                        <p className="gallery-fees-half-hour">Half Hour Fee: {recommendedFees.halfHourFee} sat/vB</p>
                        <p className="gallery-fees-hour">Hour Fee: {recommendedFees.hourFee} sat/vB</p>
                      </div>
                    )}
                  </div>
                  <button onClick={handleConfirmPurchase} disabled={isTransactionInProgress} className="gallery-confirm-button">Confirm Purchase</button>
                  <button onClick={() => setIsConfirming(false)} className="gallery-cancel-button">Cancel</button>
                </div>
              )}
            </div>
          ))}
          <PurchaseSuccessModal
            isOpen={showSuccessModal}
            transactionId={transactionId}
            artworkSrc={selectedArtwork ? selectedArtwork.iframeSrc : ''}
            onClose={() => setShowSuccessModal(false)}
          />
      
      {showCustomConfirmModal && (
      <ConfirmPurchaseModal
          isOpen={showCustomConfirmModal}
          onConfirm={handleTransactionConfirmation}
          onCancel={() => setShowCustomConfirmModal(false)}
          paymentDetails={confirmationDetails}
      />
  )}
          </div>
      );
  };
  
  export default Gallery;  