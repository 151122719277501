import React, { useRef, useEffect, useState } from 'react';
import p5 from 'p5';
import { minify } from 'terser';
import * as fflate from 'fflate';
import axios from 'axios';
import './InscribePad.css';
import ConfirmPurchaseModal from './ConfirmPurchaseModal';

const BACKEND_URL = 'https://inscription-service-production.up.railway.app';

const encodeCoordinate = (x, y) => {
  return `${Math.round(x).toString(36)},${Math.round(y).toString(36)}`;
};

const encodeColorAndWeight = (color, weight) => {
  return `${parseInt(color.slice(1), 16).toString(36)},${weight.toString(36)}`;
};

const InscribePad = () => {
  const sketchRef = useRef();
  const [drawingCoordinates, setDrawingCoordinates] = useState([]);
  const [htmlContent, setHtmlContent] = useState('');
  const [savingsPercentage, setSavingsPercentage] = useState(0);
  const [myP5Instance, setMyP5Instance] = useState(null);
  const [bgColor, setBgColor] = useState('#ffffff');
  const [orderDetails, setOrderDetails] = useState({
    receiverAddress: '',
    feeRate: 10,
  });
  const [recommendedFees, setRecommendedFees] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [savings, setSavings] = useState(0);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchRecommendedFees = async () => {
      try {
        const feesResponse = await axios.get('https://mempool.space/api/v1/fees/recommended');
        setRecommendedFees(feesResponse.data);
        setOrderDetails((prevDetails) => ({
          ...prevDetails,
          feeRate: feesResponse.data.fastestFee,
        }));
      } catch (error) {
        console.error('Error fetching recommended fees:', error);
      }
    };

    fetchRecommendedFees();
  }, []);

  useEffect(() => {
    const sketch = (p) => {
      let canvas;
      let drawing = false;

      p.setup = () => {
        canvas = p.createCanvas(600, 600);
        canvas.parent(sketchRef.current);
        p.background(bgColor);
        redrawDrawing(p);
      };

      p.mousePressed = () => {
        drawing = true;
      };

      p.mouseReleased = () => {
        drawing = false;
        setDrawingCoordinates((prevCoords) => [
          ...prevCoords,
          { type: 'end' }
        ]);
      };

      p.mouseDragged = () => {
        if (drawing && p.mouseX >= 0 && p.mouseX <= p.width && p.mouseY >= 0 && p.mouseY <= p.height) {
          const newCoord = {
            pmouseX: p.pmouseX,
            pmouseY: p.pmouseY,
            mouseX: p.mouseX,
            mouseY: p.mouseY,
            color: '#000000', // Fixed drawing color
            weight: 2 // Fixed stroke weight
          };
          setDrawingCoordinates((prevCoords) => [
            ...prevCoords,
            newCoord,
          ]);
          p.stroke('#000000'); // Fixed drawing color
          p.strokeWeight(2); // Fixed stroke weight
          p.line(newCoord.pmouseX, newCoord.pmouseY, newCoord.mouseX, newCoord.mouseY);
        }
      };

      p.windowResized = () => {
        p.resizeCanvas(600, 600);
        redrawDrawing(p);
      };
    };

    const myP5 = new p5(sketch, sketchRef.current);
    setMyP5Instance(myP5);

    return () => {
      myP5.remove();
    };
  }, []);

  useEffect(() => {
    if (myP5Instance) {
      redrawDrawing(myP5Instance);
    }
  }, [bgColor]);

  const redrawDrawing = (p) => {
    p.background(bgColor);
    p.noFill();
    drawingCoordinates.forEach(coord => {
      if (coord.type === 'end') {
        p.endShape();
      } else {
        p.stroke('#000000'); // Fixed drawing color
        p.strokeWeight(2); // Fixed stroke weight
        p.line(coord.pmouseX, coord.pmouseY, coord.mouseX, coord.mouseY);
      }
    });
  };

  const handleBgColorChange = (color) => {
    setBgColor(color);
  };

  const handleSubmit = async () => {
    if (!orderDetails.receiverAddress) {
      setError('Please add a receiver address.');
      return;
    }

    const encodedCoordinates = drawingCoordinates.map((coord, index, coords) => {
      if (coord.type === 'end') {
        return 'e';
      }
      if (index === 0 || coords[index - 1].type === 'end') {
        return `s${encodeCoordinate(coord.pmouseX, coord.pmouseY)},${encodeColorAndWeight(coord.color, coord.weight)}`;
      }
      return `d${encodeCoordinate(coord.mouseX, coord.mouseY)}`;
    }).join(';');

    const sketchScript = `
      function setup() {
        createCanvas(windowWidth, windowHeight);
        background('${bgColor}');
        noFill();
      }

      function draw() {
        const d = "${encodedCoordinates}".split(";");
        const o = width / 600;
        const e = height / 600;
        d.forEach((d) => {
          if (d.startsWith("e")) return endShape();
          if (d.startsWith("s")) {
            const [m, s, a, t] = d.slice(1).split(",");
            stroke("#" + parseInt(a, 36).toString(16).padStart(6, "0"));
            strokeWeight(parseInt(t, 36));
            beginShape();
            vertex(parseInt(m, 36) * o, parseInt(s, 36) * e);
          } else {
            const [m, s] = d.slice(1).split(",");
            vertex(parseInt(m, 36) * o, parseInt(s, 36) * e);
          }
        });
        noLoop();
      }
    `;

    try {
      const minifiedResult = await minify(sketchScript);
      if (minifiedResult.error) throw new Error(minifiedResult.error);

      const originalSize = new TextEncoder().encode(sketchScript).length;
      const compressed = fflate.gzipSync(new TextEncoder().encode(minifiedResult.code));
      const compressedSize = compressed.length;
      const base64Encoded = btoa(String.fromCharCode(...compressed));

      const savingsPercentage = ((originalSize - compressedSize) / originalSize) * 100;
      setSavings(savingsPercentage.toFixed(2));
      setIsModalOpen(true);

      const htmlContent = `
    <script>
        import("/content/d795ba6cf2ea7d4ed9c159e498ba2c9ad4295d8ea257fb1ee88e9244c016adc2i0")
        .then(ob1 => ob1.ob1Scribe("${base64Encoded}"));
    </script>
      `;

      setHtmlContent(htmlContent);

      const formData = new FormData();
      formData.append('file', new Blob([htmlContent], { type: 'text/html' }), 'compressedCode.html');

      const response = await axios.post(`${BACKEND_URL}/upload`, formData, {
        headers: {
          'User-Selected-Fee-Rate': orderDetails.feeRate,
          'User-Selected-Receiver-Address': orderDetails.receiverAddress,
        },
      });

      if (response.status === 200) {
        const result = response.data;
        setOrderDetails({
          ...orderDetails,
          totalAmount: result.payAddressAmount,
          totalAmountBTC: result.payAddressAmount / 100000000,
          payAddress: result.payAddress,
          receiverAddress: result.receiverAddress,
          devAddress: result.devAddress,
          devFee: result.devFee,
          feeRate: result.feeRate,
        });

        setDrawingCoordinates([]);
        if (myP5Instance) {
          myP5Instance.clear();
          myP5Instance.background(bgColor);
        }
      } else {
        alert('Failed to upload HTML and create order. Please try again.');
      }
    } catch (error) {
      console.error('Error compressing and encoding the drawing:', error);
    }
  };

  const handleReceiverAddressChange = (event) => {
    setOrderDetails({ ...orderDetails, receiverAddress: event.target.value });
  };

  const handleFeeRateChange = (event) => {
    setOrderDetails({ ...orderDetails, feeRate: Number(event.target.value) });
  };

  const handleConfirm = async () => {
    if (window.unisat && window.unisat.sendBitcoin) {
      try {
        const txidPay = await window.unisat.sendBitcoin(
          orderDetails.payAddress,
          orderDetails.totalAmount,
          { feeRate: parseInt(orderDetails.feeRate) }
        );
        alert('Transaction successful! TXID: ' + txidPay);
        setIsModalOpen(false);
      } catch (error) {
        console.error('Transaction failed:', error);
        alert('Transaction failed. Please try again or check your wallet.');
      }
    } else {
      alert('UniSat wallet is not available. Please install it.');
    }
  };

  return (
    <div className="inscribe-pad-container">
      <div className="color-picker">
        <div>
          <label>Background Color: </label>
          <input
            type="color"
            value={bgColor}
            onChange={(e) => handleBgColorChange(e.target.value)}
          />
        </div>
      </div>
      <div ref={sketchRef} className="inscribe-pad"></div>
      {error && <div className="error-message">{error}</div>}
      <button onClick={handleSubmit} className="submit-button">Compress and Inscribe</button>
      <div className="submission-controls">
        <div>
          <label>Receiver Address:</label>
          <input
            type="text"
            value={orderDetails.receiverAddress}
            onChange={handleReceiverAddressChange}
            placeholder="Enter receiver address"
          />
        </div>
        <div>
          <label>Fee Rate (sat/vB):</label>
          <input
            type="number"
            value={orderDetails.feeRate}
            onChange={handleFeeRateChange}
            min="1"
          />
        </div>
      </div>
      <ConfirmPurchaseModal
        isOpen={isModalOpen}
        onConfirm={handleConfirm}
        onCancel={() => setIsModalOpen(false)}
        paymentDetails={{
          payAddressAmount: orderDetails.totalAmount,
          payAddress: orderDetails.payAddress,
          receiverAddress: orderDetails.receiverAddress,
          feeRate: orderDetails.feeRate,
        }}
      />
    </div>
  );
};

export default InscribePad;
