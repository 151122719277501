import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ConfirmPurchaseModal.css';

const ConfirmPurchaseModal = ({ isOpen, onConfirm, onCancel, paymentDetails }) => {
    const [btcPriceInUsd, setBtcPriceInUsd] = useState(null);

    useEffect(() => {
        if (isOpen) {
            axios.get('https://mempool.space/api/v1/prices')
                .then((response) => {
                    setBtcPriceInUsd(response.data.USD);
                })
                .catch((error) => {
                    console.error('Error fetching BTC price:', error);
                });
        }
    }, [isOpen]);

    const convertSatoshiToUsd = (satoshi) => {
        return ((satoshi / 100000000) * btcPriceInUsd).toFixed(2);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                
                alert('Copied to clipboard');
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    if (!isOpen) return null;

    return (
        <div className="confirm-purchase-overlay">
            <div className="confirm-purchase-content">
                <h2>Confirm Your Purchase</h2>
                <p>The total cost for the inscription is <strong>{paymentDetails.payAddressAmount} satoshis</strong>
                   (<strong>${convertSatoshiToUsd(paymentDetails.payAddressAmount)} USD</strong>).</p>
                <p>If you prefer not to use UniSat wallet, send this amount in BTC to the pay address below:</p>
                <p className="highlight">
                    Pay Address: <strong>{paymentDetails.payAddress}</strong>
                    <button onClick={() => copyToClipboard(paymentDetails.payAddress)} className="copy-button">Copy</button>
                </p>
                <p className="highlight">
                    Amount in BTC: <strong>{(paymentDetails.payAddressAmount / 100000000).toFixed(8)} BTC</strong>
                    <button onClick={() => copyToClipboard((paymentDetails.payAddressAmount / 100000000).toFixed(8))} className="copy-button">Copy</button>
                </p>
                <p>Receiver Address: <strong>{paymentDetails.receiverAddress}</strong></p>
                <p>Fee Rate: <strong>{paymentDetails.feeRate} sat/vB</strong></p>
                <div className="confirm-purchase-actions">
                    <button onClick={onConfirm} className="confirm-button">Confirm with UniSat</button>
                    <button onClick={onCancel} className="cancel-button">Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmPurchaseModal;
