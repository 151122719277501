// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-compress-magic-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Changed to flex-start for better alignment */
    min-height: 100vh; /* Full height to ensure vertical centering */
    padding: 20px;
    box-sizing: border-box;
  }
  
  .video-compress-magic-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 500px;
    border: 1px solid #000; /* Optional: to see the boundary of the container */
    margin: 20px 0;
    position: relative; /* Added for positioning canvas */
  }
  
  .generated-code {
    width: 100%;
    max-width: 640px;
    margin: 20px 0;
    background: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto;
    text-align: left;
  }
  
  button {
    padding: 10px 20px;
    margin: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  
  input[type="file"] {
    margin: 10px;
  }
  
  @media (max-width: 600px) {
    .video-compress-magic-preview {
      width: 100%;
      height: auto;
      border: none;
    }
  
    .generated-code {
      width: 100%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/VideoCompressMagic.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B,EAAE,+CAA+C;IAC5E,iBAAiB,EAAE,6CAA6C;IAChE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,sBAAsB,EAAE,mDAAmD;IAC3E,cAAc;IACd,kBAAkB,EAAE,iCAAiC;EACvD;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE;MACE,WAAW;MACX,YAAY;MACZ,YAAY;IACd;;IAEA;MACE,WAAW;IACb;EACF","sourcesContent":[".video-compress-magic-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start; /* Changed to flex-start for better alignment */\n    min-height: 100vh; /* Full height to ensure vertical centering */\n    padding: 20px;\n    box-sizing: border-box;\n  }\n  \n  .video-compress-magic-preview {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 500px;\n    height: 500px;\n    border: 1px solid #000; /* Optional: to see the boundary of the container */\n    margin: 20px 0;\n    position: relative; /* Added for positioning canvas */\n  }\n  \n  .generated-code {\n    width: 100%;\n    max-width: 640px;\n    margin: 20px 0;\n    background: #f0f0f0;\n    padding: 10px;\n    border-radius: 5px;\n    overflow-x: auto;\n    text-align: left;\n  }\n  \n  button {\n    padding: 10px 20px;\n    margin: 10px;\n    font-size: 16px;\n    cursor: pointer;\n  }\n  \n  input[type=\"file\"] {\n    margin: 10px;\n  }\n  \n  @media (max-width: 600px) {\n    .video-compress-magic-preview {\n      width: 100%;\n      height: auto;\n      border: none;\n    }\n  \n    .generated-code {\n      width: 100%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
