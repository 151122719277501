// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-dashboard {
    font-family: Arial, sans-serif;
  }
  
  .order-item {
    margin: 10px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
  }
  
  input, select {
    margin: 5px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 8px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Orders.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;EAChC;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".order-dashboard {\n    font-family: Arial, sans-serif;\n  }\n  \n  .order-item {\n    margin: 10px;\n    padding: 10px;\n    border: 1px solid gray;\n    border-radius: 5px;\n  }\n  \n  input, select {\n    margin: 5px;\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  button {\n    padding: 8px 15px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
