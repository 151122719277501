import React, { useState, useEffect } from 'react';
import { getBalance } from '../api/walletApi';
import axios from 'axios';
import './BalanceDisplay.css'; 

const BalanceDisplay = () => {
    const [balance, setBalance] = useState(null);
    const [btcPrice, setBtcPrice] = useState(null);

    useEffect(() => {
        const fetchBalanceAndBtcPrice = async () => {
            try {
                const fetchedBalance = await getBalance();
                setBalance(fetchedBalance);
            } catch (e) {
                console.error('Error fetching balance:', e);
            }

            try {
                const pricesResponse = await axios.get('https://mempool.space/api/v1/prices');
                setBtcPrice(pricesResponse.data.USD);
            } catch (error) {
                console.error('Error fetching BTC price:', error);
            }
        };

        fetchBalanceAndBtcPrice();
    }, []);

    const convertSatoshisToUsd = (satoshis) => {
        if (!btcPrice) return 0;
        const satoshisInOneBtc = 100000000;
        return ((satoshis / satoshisInOneBtc) * btcPrice).toFixed(2);
    };

    return (
        <div className="balance-display-container"> {/* Use the class name for the container */}
            <h2 className="balance-display-heading">Your Balance</h2> {/* Use the class name for the heading */}
            {balance && btcPrice ? (
                <div>
                    <p className="balance-detail">Confirmed: <span>{balance.confirmed} satoshis</span> (${convertSatoshisToUsd(balance.confirmed)} USD)</p> {/* Use the class name for detail paragraphs */}
                    <p className="balance-detail">Unconfirmed: <span>{balance.unconfirmed} satoshis</span> (${convertSatoshisToUsd(balance.unconfirmed)} USD)</p>
                    <p className="balance-detail">Total: <span>{balance.total} satoshis</span> (${convertSatoshisToUsd(balance.total)} USD)</p>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default BalanceDisplay;
