
import { isWalletInstalled } from '../utils/walletUtils';


export const connectWallet = async () => {
    try {
        if (isWalletInstalled()) {
            const accounts = await window.unisat.requestAccounts();
            console.log('connect success', accounts);
            return accounts;
        } else {
            console.log('UniSat Wallet is not installed');
            return null;
        }
    } catch (e) {
        console.error('connect failed', e);
        return null;
    }
};


export const getBalance = async () => {
    try {
        if (!isWalletInstalled()) {
            console.log('UniSat Wallet is not installed');
            return null;
        }

        const balance = await window.unisat.getBalance();
        console.log('Fetched balance:', balance);
        return balance;
    } catch (e) {
        console.error('Fetching balance failed', e);
        throw e;
    }
};


export const getInscriptions = async (cursor = 0, size = 10) => {
    try {
        if (!isWalletInstalled()) {
            console.log('UniSat Wallet is not installed');
            return [];
        }

        const inscriptions = await window.unisat.getInscriptions(cursor, size);
        console.log('Fetched inscriptions:', inscriptions);
        return inscriptions.list; 
    } catch (e) {
        console.error('Fetching inscriptions failed', e);
        throw e;
    }
};


export const sendInscription = async (address, inscriptionId, options = {}) => {
    try {
        const { txid } = await window.unisat.sendInscription(address, inscriptionId, options);
        console.log('send inscription success', txid);
        return txid;
    } catch (e) {
        console.error('send inscription failed', e);
        throw e;
    }
};
