// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ib-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
  }
  
  .ib-item {
    background: #880bd0;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: relative;
    overflow: hidden;
  }
  
  .ib-view-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .ib-details-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .ib-details-column {
    flex: 1 1;
    padding: 0 20px;
  }
  
  .ib-preview {
    width: 100%;
    height: 300px;
  }
  
  .ib-error, .ib-no-inscriptions {
    text-align: center;
    color: #fa0404;
  }
  
  .ib-block-height {
    text-align: center;
    color: #08f0ba;
    margin-bottom: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/InscriptionBlock.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,aAAa;EACf;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;IAClB,qCAAqC;IACrC,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,aAAa;EACf;;EAEA;IACE,SAAO;IACP,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,mBAAmB;EACrB","sourcesContent":[".ib-container {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    padding: 20px;\n  }\n  \n  .ib-item {\n    background: #880bd0;\n    border-radius: 4px;\n    box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n    position: relative;\n    overflow: hidden;\n  }\n  \n  .ib-view-btn {\n    position: absolute;\n    top: 10px;\n    left: 10px;\n    padding: 8px 16px;\n    border: none;\n    background-color: #007bff;\n    color: white;\n    cursor: pointer;\n  }\n  \n  .ib-details-container {\n    display: flex;\n    justify-content: space-between;\n    padding: 20px;\n  }\n  \n  .ib-details-column {\n    flex: 1;\n    padding: 0 20px;\n  }\n  \n  .ib-preview {\n    width: 100%;\n    height: 300px;\n  }\n  \n  .ib-error, .ib-no-inscriptions {\n    text-align: center;\n    color: #fa0404;\n  }\n  \n  .ib-block-height {\n    text-align: center;\n    color: #08f0ba;\n    margin-bottom: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
