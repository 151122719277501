import React, { useState, useEffect, useCallback } from 'react';
import './InscriptionBlock.css';

const InscriptionBlock = () => {
  const [inscriptions, setInscriptions] = useState([]);
  const [currentBlockHeight, setCurrentBlockHeight] = useState('');
  const [error, setError] = useState(null);
  const [selectedInscriptionId, setSelectedInscriptionId] = useState(null);

  const fetchInscriptionTransfers = useCallback(async (height) => {
    const apiUrl = `https://api.hiro.so/ordinals/v1/inscriptions/transfers?block=${height}&limit=60`;
    try {
      const response = await fetch(apiUrl, {
        headers: { 'Accept': 'application/json' }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch inscriptions');
      }
      const data = await response.json();
      setInscriptions(data.results);
    } catch (err) {
      setError(`Inscriptions Fetch Error: ${err.message}`);
    }
  }, []);

  const fetchCurrentBlockHeight = useCallback(async () => {
    try {
      const response = await fetch('https://mempool.space/api/blocks/tip/height');
      if (!response.ok) {
        throw new Error('Failed to fetch current block height');
      }
      const height = await response.text();
      setCurrentBlockHeight(height);
      setInscriptions([]);
      fetchInscriptionTransfers(height);
    } catch (err) {
      setError(`Block Height Fetch Error: ${err.message}`);
    }
  }, [fetchInscriptionTransfers]);

  useEffect(() => {
    fetchCurrentBlockHeight();
    const interval = setInterval(fetchCurrentBlockHeight, 300000);
    return () => clearInterval(interval);
  }, [fetchCurrentBlockHeight]);

  const handleInscriptionClick = (id) => {
    setSelectedInscriptionId(id === selectedInscriptionId ? null : id);
  };

  return (
    <div className="ib-container">
      {error && <p className="ib-error">{error}</p>}
      {currentBlockHeight && <h2 className="ib-block-height">Current Block Height: {currentBlockHeight}</h2>}
  
      {inscriptions.length > 0 ? (
        inscriptions.map(inscription => (
          <div key={inscription.id} className="ib-item">
            <button className="ib-view-btn" onClick={() => handleInscriptionClick(inscription.id)}>
              View
            </button>
  
            {selectedInscriptionId === inscription.id ? (
              <div className="ib-details-container">
                <div className="ib-details-column">
                  <p><strong>Seller:</strong></p>
                  <p>ID: {inscription.id}</p>
                  <p>Block Height: {inscription.from.block_height}</p>
                  <p>Block Hash: {inscription.from.block_hash}</p>
                  <p>Address: {inscription.from.address}</p>
                  <p>Transaction ID: {inscription.from.tx_id}</p>
                  <p>Location: {inscription.from.location}</p>
                  <p>Output: {inscription.from.output}</p>
                  <p>Value: {inscription.from.value}</p>
                  <p>Timestamp: {new Date(inscription.from.timestamp).toLocaleString()}</p>
                </div>
                <div className="ib-details-column">
                  <p><strong>Buyer:</strong></p>
                  <p>ID: {inscription.id}</p>
                  <p>Block Height: {inscription.to.block_height}</p>
                  <p>Block Hash: {inscription.to.block_hash}</p>
                  <p>Address: {inscription.to.address}</p>
                  <p>Transaction ID: {inscription.to.tx_id}</p>
                  <p>Location: {inscription.to.location}</p>
                  <p>Output: {inscription.to.output}</p>
                  <p>Value: {inscription.to.value}</p>
                  <p>Timestamp: {new Date(inscription.to.timestamp).toLocaleString()}</p>
                </div>
              </div>
            ) : (
              <iframe
                className="ib-preview"
                src={`https://ordinals.com/preview/${inscription.id}`}
                title={`Inscription Preview ${inscription.id}`}
                frameBorder="0"
                width="100%"
                height="300px"
                loading="lazy"
              ></iframe>
            )}
          </div>
        ))
      ) : <p className="ib-no-inscriptions">No inscriptions found for the current block.</p>}
    </div>
  );
  
};

export default InscriptionBlock;