import React, { useState } from 'react';
import { connectWallet } from '../api/walletApi';
import styles from './WalletConnector.module.css';

const WalletConnector = ({ onConnected }) => {
    const [walletAddress, setWalletAddress] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isHovering, setIsHovering] = useState(false);

    const handleConnect = async () => {
        setIsLoading(true);
        try {
            const accounts = await connectWallet();
            if (accounts && accounts.length > 0) {
                setWalletAddress(accounts[0]);
                onConnected(accounts[0]);
            } else {
                console.error('No accounts found. Make sure the wallet is connected and the correct network is selected.');
            }
        } catch (error) {
            console.error('Error connecting to wallet:', error);
        }
        setIsLoading(false);
    };

    const formatWalletAddress = (address) => {
        if (!address) return '';
        return `${address.slice(0, 4)}...${address.slice(-4)}`;
    };

    return (
        <div className={styles.walletConnectorContainer}>
            <button 
                className={styles.walletConnectButton} 
                onClick={handleConnect} 
                disabled={isLoading}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                {isLoading ? 'Connecting...' : 'Connect Wallet'}
            </button>
            {walletAddress && (
                <div className={styles.walletAddress}>
                    Connected: {formatWalletAddress(walletAddress)}
                </div>
            )}
        </div>
    );
};

export default WalletConnector;
