import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as fflate from 'fflate';
import './P5jsCodeCompressMagic.css'; 
import ConfirmPurchaseModal from './ConfirmPurchaseModal';

const BACKEND_URL = 'https://inscription-service-production.up.railway.app';


const SavingsModal = ({ isOpen, onClose, savings }) => {
  if (!isOpen) return null;
  return (
    <div className="modal">
      <div className="modal-content">
        <h4>Compression Savings</h4>
        <p>You saved {savings}% in file size by compressing your code.</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

const HTMLCompressMagic = () => {
  const [inputHtml, setInputHtml] = useState('');  
  const [outputHtml, setOutputHtml] = useState('');
  const [orderDetails, setOrderDetails] = useState({
    totalAmount: 0,
    totalAmountBTC: '',
    payAddress: '',
    receiverAddress: '',
    devAddress: '',
    devFee: 0,
    feeRate: 10,
  });
  const [recommendedFees, setRecommendedFees] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [savingsModalOpen, setSavingsModalOpen] = useState(false);
  const [savings, setSavings] = useState(0);
  const iframeRef = useRef(null); 

  useEffect(() => {
    const fetchRecommendedFees = async () => {
      try {
        const response = await axios.get('https://mempool.space/api/v1/fees/recommended');
        setRecommendedFees(response.data);
      } catch (error) {
        console.error('Error fetching recommended fees:', error);
      }
    };
    fetchRecommendedFees();
  }, []);


  const stringToArray = (bufferString) => {
    return new TextEncoder('utf-8').encode(bufferString);
  };

  
  const uint8ToBase64 = (arr) => {
    return btoa(
      Array.from(arr)
        .map((byte) => String.fromCharCode(byte))
        .join('')
    );
  };

  const handleCompressAndGenerateHTML = async () => {
    try {
      const gzipped = fflate.gzipSync(stringToArray(inputHtml), {
        filename: 'aMassiveFile.txt',
        mtime: '9/1/16 2:00 PM',
      });

      const b64encoded = uint8ToBase64(gzipped);

      const pageStructureb64 =
        'PGh0bWw+PGhlYWQ+PG1ldGEgY2hhcnNldD0idXRmLTgiPjxzdHlsZT5ib2R5IHttYXJnaW46IDA7fTwvc3R5bGU+PC9oZWFkPjxib2R5PjxkaXYgaWQ9InNjZW5lIj48L2Rpdj48c2NyaXB0PgpkMz0nU1JDX0NPTVBSRVNTRURfU1RSSU5HJzsKZnVuY3Rpb24gZmZsYXRlQ2FsbGJhY2soKXsKdHQ9ZmZsYXRlLnN0ckZyb21VOChmZmxhdGUuZ3VuemlwU3luYyhuZXcgVWludDhBcnJheShBcnJheS5mcm9tKGF0b2IoZDMpKS5tYXAoKGNoYXIpPT5jaGFyLmNoYXJDb2RlQXQoMCkpKSkpOwpkb2N1bWVudC5ib2R5LmlubmVySFRNTD10dDsKbWF0Y2hlcz10dC5tYXRjaEFsbCgvPHNjcmlwdFtePl0qPiguKj8pPFwvc2NyaXB0Pi9pc2cpOwpmb3IobWF0Y2ggb2YgbWF0Y2hlcyl7CnNjcmlwdD1kb2N1bWVudC5jcmVhdGVFbGVtZW50KCdzY3JpcHQnKTsKc2NyaXB0LmlubmVySFRNTD1tYXRjaFsxXTsKZG9jdW1lbnQuYm9keS5hcHBlbmRDaGlsZChzY3JpcHQpO30Kd2luZG93Lm9ubG9hZCgpO30KKGFzeW5jIGZ1bmN0aW9uKCl7cmVzcG9uc2U9YXdhaXQgZmV0Y2goYC9jb250ZW50LzJkYmRmOWViYmVjNmJlNzkzZmQxNmFlOWI3OTdjN2NmOTY4YWIyNDI3MTY2YWFmMzkwYjkwYjcxNzc4MjY2YWJpMGApOwpodG1sPWF3YWl0IHJlc3BvbnNlLnRleHQoKTsKaHRtbExpbmVzPWh0bWwuc3BsaXQoIlxuIik7CmZmbGF0ZVNjcmlwdD1odG1sTGluZXNbMjhdOwpmZmxhdGVDYWxsYmFja1NjcmlwdD0iZmZsYXRlQ2FsbGJhY2soKTsiOwpuZXdTY3JpcHQ9ZG9jdW1lbnQuY3JlYXRlRWxlbWVudCgnc2NyaXB0Jyk7Cm5ld1NjcmlwdC5pbm5lckhUTUw9W2ZmbGF0ZVNjcmlwdCxmZmxhdGVDYWxsYmFja1NjcmlwdF0uam9pbigiO1xuIik7CmRvY3VtZW50LmJvZHkuYXBwZW5kQ2hpbGQobmV3U2NyaXB0KTt9KSgpOwo8L3NjcmlwdD48L2JvZHk+PC9odG1sPgo=';
      let pageStructure = atob(pageStructureb64);

      pageStructure = pageStructure.replace('SRC_COMPRESSED_STRING', b64encoded);

      
      const viewerHtml = inputHtml.replace(/\/content\//g, 'https://ordinals.com/content/');

      setOutputHtml(pageStructure);

      if (iframeRef.current) {
        const blob = new Blob([viewerHtml], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        iframeRef.current.src = url;
      }

      const originalSize = new TextEncoder().encode(inputHtml).length;
      const compressedSize = gzipped.length;
      const savingsPercentage = ((originalSize - compressedSize) / originalSize * 100).toFixed(2);
      setSavings(savingsPercentage);
      setSavingsModalOpen(true);
    } catch (error) {
      console.error('Compression error:', error);
    }
  };

  const handleReceiverAddressChange = (event) => {
    setOrderDetails({ ...orderDetails, receiverAddress: event.target.value });
  };

  const handleFeeRateChange = (event) => {
    setOrderDetails({ ...orderDetails, feeRate: event.target.value });
  };

  const handleSubmit = async () => {
    if (!outputHtml) {
      alert('Please generate the HTML first.');
      return;
    }

    const blob = new Blob([outputHtml], { type: 'text/html' });
    const formData = new FormData();
    formData.append('file', blob, 'compressedCode.html');

    try {
      const response = await fetch(`${BACKEND_URL}/upload`, {
        method: 'POST',
        body: formData,
        headers: {
          'User-Selected-Fee-Rate': orderDetails.feeRate,
          'User-Selected-Receiver-Address': orderDetails.receiverAddress,
        },
      });

      if (response.ok) {
        const result = await response.json();
        setOrderDetails({
          ...orderDetails,
          totalAmount: result.payAddressAmount,
          totalAmountBTC: result.payAddressAmount / 100000000,
          payAddress: result.payAddress,
          receiverAddress: result.receiverAddress,
          devAddress: result.devAddress,
          devFee: result.devFee,
          feeRate: result.feeRate,
        });
        setIsModalOpen(true);
      } else {
        alert('Failed to upload HTML and create order. Please try again.');
      }
    } catch (error) {
      alert('An error occurred while processing your request.');
      console.error('Error during the fetch request:', error);
    }
  };

  const handleConfirm = async () => {
    if (window.unisat && window.unisat.sendBitcoin) {
      try {
        const txidPay = await window.unisat.sendBitcoin(orderDetails.payAddress, orderDetails.totalAmount, { feeRate: parseInt(orderDetails.feeRate) });
        console.log('Payment transaction successful. TXID:', txidPay);
        alert('Transaction successful! TXID: ' + txidPay);
        setIsModalOpen(false);
      } catch (error) {
        console.error('Transaction failed:', error);
        alert('Transaction failed. Please try again or check your wallet.');
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="container mx-auto text-center">
      <h1 className="title text-xl font-bold mb-1">HTML Code Compressor and HTML Generator</h1>
      <p className="text-xs mb-4">Powered by OCM Dimensions</p>
      
      
      <textarea
        className="textarea"
        value={inputHtml}
        onChange={(e) => setInputHtml(e.target.value)}
        onFocus={() => setInputHtml('')}  
        placeholder="Paste HTML..."
      ></textarea>
      
     
      <button className="button" onClick={handleCompressAndGenerateHTML}>Compress & Generate HTML</button>
      
      
      <SavingsModal isOpen={savingsModalOpen} onClose={() => setSavingsModalOpen(false)} savings={savings} />

     
      <div className="iframe-container">
        <h3>Ordinal Content Viewer:</h3>
        <iframe
          ref={iframeRef}
          style={{ width: '100%', height: '500px', border: '1px solid black' }}
          title="Processed HTML Output Viewer"
        />
      </div>

      <div>
        <label>Receiver Address:</label>
        <input type="text" value={orderDetails.receiverAddress} onChange={handleReceiverAddressChange} placeholder="Enter receiver address" />
      </div>
      
      <div>
        <label>Fee Rate (sat/vB):</label>
        <input type="number" value={orderDetails.feeRate} onChange={handleFeeRateChange} min="1" />
        {recommendedFees.fastestFee && (
          <div>
            <p>Fastest Fee: {recommendedFees.fastestFee} sat/vB</p>
            <p>Half Hour Fee: {recommendedFees.halfHourFee} sat/vB</p>
            <p>Hour Fee: {recommendedFees.hourFee} sat/vB</p>
          </div>
        )}
      </div>

  
      <button className="button" onClick={handleSubmit} disabled={!outputHtml}>Inscribe Compressed HTML</button>

    
      <ConfirmPurchaseModal
        isOpen={isModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        paymentDetails={{
          payAddressAmount: orderDetails.totalAmount,
          payAddress: orderDetails.payAddress,
          receiverAddress: orderDetails.receiverAddress,
          feeRate: orderDetails.feeRate
        }}
      />
    </div>
  );
};

export default HTMLCompressMagic;
