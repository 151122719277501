import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import WalletConnector from './WalletConnector'; // No need to import since it's not used
import PurchaseSuccessModal from './PurchaseSuccessModal';
import ConfirmPurchaseModal from './ConfirmPurchaseModal';
import './Exhibition.css'; // Base styles
import './ThumbnailStyles.css'; // Thumbnail-specific styles

const Exhibition = () => {
    const [artworks, setArtworks] = useState([]);
    const [collections, setCollections] = useState([]);
    const [selectedArtwork, setSelectedArtwork] = useState(null);
    const [walletAddress, setWalletAddress] = useState('');
    const [userSelectedFeeRate, setUserSelectedFeeRate] = useState(10);
    const [recommendedFees, setRecommendedFees] = useState({});
    const [isConfirming, setIsConfirming] = useState(false);
    const [isTransactionInProgress, setIsTransactionInProgress] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [transactionId, setTransactionId] = useState('');
    const [showCustomConfirmModal, setShowCustomConfirmModal] = useState(false);
    const [confirmationDetails, setConfirmationDetails] = useState({});
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [showCreateCollectionModal, setShowCreateCollectionModal] = useState(false);

    const [collectionRequest, setCollectionRequest] = useState({
        title: '',
        description: '',
        price: '',
        artist: '',
        iframeSrc: '',
        sellerAddress: '',
        items: '',
        inscriptions: ['']
    });

    useEffect(() => {
        const fetchArtworksAndFees = async () => {
            try {
                const artworksResponse = await axios.get('https://exhibition-service-production.up.railway.app/api/artworks');
                if (Array.isArray(artworksResponse.data)) {
                    setArtworks(artworksResponse.data);

                    // Group artworks by collectionTitle to get unique collections
                    const groupedCollections = artworksResponse.data.reduce((acc, art) => {
                        if (!acc.some(col => col.collectionTitle === art.collectionTitle)) {
                            acc.unshift({
                                collectionTitle: art.collectionTitle,
                                iframeSrc: art.iframeSrc,
                                description: art.description,
                                price: art.price,
                                artist: art.artist,
                                xHandle: art.xHandle
                            });
                        }
                        return acc;
                    }, []);

                    setCollections(groupedCollections); // Ensure all collections are shown
                } else {
                    console.error('Received data is not an array:', artworksResponse.data);
                }

                const feesResponse = await axios.get('https://mempool.space/api/v1/fees/recommended');
                setRecommendedFees(feesResponse.data);
                setUserSelectedFeeRate(feesResponse.data.halfHourFee);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchArtworksAndFees();
    }, []);

    const handleRequestToList = async () => {
        try {
            if (!collectionRequest.items || isNaN(collectionRequest.items)) {
                alert('Please enter a valid number for the supply (items).');
                return;
            }

            await axios.post('https://exhibition-service-production.up.railway.app/api/request-listing', {
                ...collectionRequest,
                iframeSrc: `https://ordinals.com/preview/${collectionRequest.inscriptions[0]}` // Set the iframeSrc for the first inscription
            });
            setShowRequestModal(true);
            setCollectionRequest({
                title: '',
                description: '',
                price: '',
                artist: '',
                iframeSrc: '',
                sellerAddress: '',
                items: '',
                inscriptions: ['']
            });
            setShowCreateCollectionModal(false); // Close the modal after submission
        } catch (error) {
            console.error("Error submitting collection request:", error);
            alert('An error occurred while submitting your request.');
        }
    };

    const addNewInscriptionField = () => {
        setCollectionRequest(prevRequest => ({
            ...prevRequest,
            inscriptions: [...prevRequest.inscriptions, '']
        }));
    };

    const handleInscriptionChange = (index, value) => {
        setCollectionRequest(prevRequest => {
            const newInscriptions = [...prevRequest.inscriptions];
            newInscriptions[index] = value;
            return { ...prevRequest, inscriptions: newInscriptions };
        });
    };

    const handleBuy = (artwork) => {
        if (!isTransactionInProgress && (artwork.items - artwork.sold > 0)) {
            setSelectedArtwork(artwork);
            setIsConfirming(true);
        }
    };

    const handleConfirmPurchase = async () => {
        if (isTransactionInProgress) {
            console.log('Transaction already in progress - Ignoring additional request');
            return;
        }

        if (!selectedArtwork || !walletAddress) {
            alert('Please connect your wallet and select an artwork.');
            return;
        }

        setIsTransactionInProgress(true);
        const feeRate = parseInt(userSelectedFeeRate, 10);

        try {
            const purchaseData = {
                artworkId: selectedArtwork.id,
                userSelectedFeeRate: feeRate,
                userSelectedReceiverAddress: walletAddress,
            };

            const response = await axios.post('https://exhibition-service-production.up.railway.app/api/purchase', purchaseData);
            const result = response.data;

            setConfirmationDetails({
                payAddressAmount: result.payAddressAmount,
                payAddress: result.payAddress,
                receiverAddress: result.receiverAddress,
                feeRate: userSelectedFeeRate,
            });
            setShowCustomConfirmModal(true);
        } catch (error) {
            console.error("Error during the purchase request:", error);
            alert('An error occurred while processing your purchase.');
        } finally {
            setIsTransactionInProgress(false);
            setIsConfirming(false);
        }
    };

    const handlePasteAddress = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setWalletAddress(text);
        } catch (error) {
            console.error('Failed to read clipboard contents:', error);
        }
    };

    const handleTransactionConfirmation = async () => {
        setShowCustomConfirmModal(false);

        if (window.unisat) {
            try {
                const txId = await window.unisat.sendBitcoin(
                    confirmationDetails.payAddress,
                    confirmationDetails.payAddressAmount,
                    { feeRate: confirmationDetails.feeRate }
                );

                if (txId) {
                    setTransactionId(txId);

                    const newSoldCount = selectedArtwork.sold + 1;
                    setArtworks((prevArtworks) => prevArtworks.map((artwork) => {
                        if (artwork.id === selectedArtwork.id) {
                            return { ...artwork, sold: newSoldCount };
                        }
                        return artwork;
                    }));

                    await axios.post('https://exhibition-service-production.up.railway.app/api/artworks/update-supply', {
                        artworkId: selectedArtwork.id,
                        sold: newSoldCount
                    });

                    setShowSuccessModal(true);
                }
            } catch (error) {
                console.error("Error during the payment transaction:", error);
                alert('An error occurred while processing your payment.');
            }
        }
    };

    const handleViewCollection = (collection) => {
        setSelectedCollection(collection);
    };

    const handleBackToThumbnails = () => {
        setSelectedCollection(null);
    };

    return (
        <div className="background-container">
            <div className="exhibition-container">
                {/* Removed WalletConnector component */}
    
                {/* Auto-scrolling Banner for Recent Collections */}
                {!selectedCollection && (
                    <div className="scrolling-banner">
                        <div className="scrolling-banner-content">
                            {collections.map((collection, index) => (
                                <div key={index} className="scrolling-banner-item">
                                    <iframe
                                        src={collection.iframeSrc}
                                        title={collection.collectionTitle}
                                        frameBorder="0"
                                        scrolling="no"
                                        className="mini-thumbnail-iframe"
                                    ></iframe>
                                    <span className="scrolling-banner-title">
                                        {collection.collectionTitle}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
    
                {!selectedCollection && (
                    <>
                        <button
                            className="create-collection-button-custom"
                            onClick={() => setShowCreateCollectionModal(true)}
                        >
                            Create Collection
                        </button>
    
                        <div className="collections-section">
                            {collections.map(collection => (
                                <div key={collection.collectionTitle} className="collection-item">
                                    <div className="thumbnail-container">
                                        <iframe
                                            src={collection.iframeSrc}
                                            title={collection.collectionTitle}
                                            frameBorder="0"
                                            scrolling="no"
                                            className="thumbnail-iframe"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                    <div className="collection-details">
                                        <div>
                                            <p className="collection-info-label">New Collection:</p>
                                            <h3 className="collection-title">{collection.collectionTitle}</h3>
                                        </div>
                                        <div>
                                            <p className="collection-info-label">Artist:</p>
                                            <p className="collection-artist">{collection.artist}</p>
                                        </div>
                                        <button
                                            onClick={() => handleViewCollection(collection)}
                                            className="view-collection-button"
                                        >
                                            View Collection
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
    
                {selectedCollection && (
                    <div className="selected-collection-section">
                        <div className="artist-info-container">
                            <p>{selectedCollection.title}</p>
                            <p>{selectedCollection.description}</p>
                            <p>Artist: {selectedCollection.artist}</p>
                            <p>X Handle: {selectedCollection.xHandle}</p>
                            <p>Price: {selectedCollection.price} BTC</p>
                        </div>
                        <div className="selected-collection-artworks">
                            {artworks
                                .filter(artwork => artwork.collectionTitle === selectedCollection.collectionTitle)
                                .map(artwork => (
                                    <div
                                        key={artwork.id}
                                        className={`art-container ${
                                            isConfirming && selectedArtwork && selectedArtwork.id === artwork.id
                                                ? 'art-container-with-confirmation'
                                                : ''
                                        }`}
                                    >
                                        <div className="art-iframe-wrapper">
                                            <iframe
                                                src={artwork.iframeSrc}
                                                title={artwork.title}
                                                frameBorder="0"
                                                scrolling="no"
                                                className="art-iframe"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                        <div className="art-text-container">
                                            <h3 className="art-title">{artwork.title}</h3>
                                            <p className="art-description">{artwork.description}</p>
                                            <p className="art-supply">
                                                Supply: {artwork.items - artwork.sold} / {artwork.items}
                                            </p>
                                            <p className="art-price">Price: {artwork.price}</p>
                                            <button
                                                onClick={() => handleBuy(artwork)}
                                                disabled={
                                                    isTransactionInProgress ||
                                                    isConfirming ||
                                                    artwork.sold >= artwork.items
                                                }
                                                className="art-buy-button"
                                            >
                                                {artwork.sold < artwork.items ? 'Buy' : 'Sold Out'}
                                            </button>
                                        </div>
                                        {isConfirming &&
                                            selectedArtwork &&
                                            selectedArtwork.id === artwork.id && (
                                                <div className="exhibition-purchase-confirmation">
                                                    <h2 className="exhibition-confirmation-title">Confirm Purchase</h2>
                                                    <p className="exhibition-confirmation-art-title">
                                                        You are about to purchase: {selectedArtwork.title}
                                                    </p>
                                                    <p className="exhibition-confirmation-total-fee">
                                                        Total Fee: {selectedArtwork.price}
                                                    </p>
                                                    <p className="exhibition-confirmation-fee-rate">
                                                        Fee Rate: {userSelectedFeeRate} sat/vB
                                                    </p>
                                                    <div className="exhibition-confirmation-receiver-address">
                                                        <label>Receiver Address:</label>
                                                        <input
                                                            type="text"
                                                            value={walletAddress}
                                                            onChange={e => setWalletAddress(e.target.value)}
                                                        />
                                                        <button
                                                            onClick={handlePasteAddress}
                                                            className="exhibition-paste-address-button"
                                                        >
                                                            Paste Address
                                                        </button>
                                                    </div>
                                                    <div className="exhibition-confirmation-fee-rate-input">
                                                        <label>Fee Rate (sat/vB):</label>
                                                        <input
                                                            type="number"
                                                            value={userSelectedFeeRate}
                                                            onChange={e => setUserSelectedFeeRate(e.target.value)}
                                                            min="1"
                                                        />
                                                        {recommendedFees.fastestFee && (
                                                            <div className="exhibition-recommended-fees">
                                                                <p>Fastest Fee: {recommendedFees.fastestFee} sat/vB</p>
                                                                <p>Half Hour Fee: {recommendedFees.halfHourFee} sat/vB</p>
                                                                <p>Hour Fee: {recommendedFees.hourFee} sat/vB</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <button
                                                        onClick={handleConfirmPurchase}
                                                        disabled={isTransactionInProgress}
                                                        className="exhibition-confirm-purchase-button"
                                                    >
                                                        Confirm Purchase
                                                    </button>
                                                    <button
                                                        onClick={() => setIsConfirming(false)}
                                                        className="exhibition-cancel-button"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            )}
                                    </div>
                                ))}
                        </div>
                        <button
                            onClick={handleBackToThumbnails}
                            className="back-to-thumbnails-button"
                        >
                            Back to Collections
                        </button>
                    </div>
                )}
    
                {showCreateCollectionModal && (
                    <div className="create-collection-modal">
                        <div className="create-collection-content">
                            <h2>Create a New Collection</h2>
                            <form
                                onSubmit={e => {
                                    e.preventDefault();
                                    handleRequestToList();
                                }}
                            >
                                <input
                                    type="text"
                                    placeholder="Title"
                                    value={collectionRequest.title}
                                    onChange={e =>
                                        setCollectionRequest({
                                            ...collectionRequest,
                                            title: e.target.value,
                                        })
                                    }
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="Description"
                                    value={collectionRequest.description}
                                    onChange={e =>
                                        setCollectionRequest({
                                            ...collectionRequest,
                                            description: e.target.value,
                                        })
                                    }
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="Price (BTC)"
                                    value={collectionRequest.price}
                                    onChange={e =>
                                        setCollectionRequest({
                                            ...collectionRequest,
                                            price: e.target.value,
                                        })
                                    }
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="Artist"
                                    value={collectionRequest.artist}
                                    onChange={e =>
                                        setCollectionRequest({
                                            ...collectionRequest,
                                            artist: e.target.value,
                                        })
                                    }
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="X Handle (Twitter)"
                                    value={collectionRequest.xHandle}
                                    onChange={e =>
                                        setCollectionRequest({
                                            ...collectionRequest,
                                            xHandle: e.target.value,
                                        })
                                    }
                                />
                                <input
                                    type="text"
                                    placeholder="Inscription ID (Thumbnail)"
                                    value={collectionRequest.inscriptions[0]}
                                    onChange={e => handleInscriptionChange(0, e.target.value)}
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="Seller Address"
                                    value={collectionRequest.sellerAddress}
                                    onChange={e =>
                                        setCollectionRequest({
                                            ...collectionRequest,
                                            sellerAddress: e.target.value,
                                        })
                                    }
                                    required
                                />
                                <input
                                    type="number"
                                    placeholder="Supply (items)"
                                    value={collectionRequest.items}
                                    onChange={e =>
                                        setCollectionRequest({
                                            ...collectionRequest,
                                            items: e.target.value,
                                        })
                                    }
                                    required
                                />
    
                                <label>Inscriptions:</label>
                                {collectionRequest.inscriptions
                                    .slice(1)
                                    .map((inscription, index) => (
                                        <input
                                            key={index + 1}
                                            type="text"
                                            placeholder={`Inscription ID #${index + 2}`}
                                            value={inscription}
                                            onChange={e =>
                                                handleInscriptionChange(
                                                    index + 1,
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                    ))}
                                <button
                                    type="button"
                                    onClick={addNewInscriptionField}
                                >
                                    Add Another Inscription ID
                                </button>
    
                                <button type="submit">Submit Collection</button>
                            </form>
                            <button
                                onClick={() => setShowCreateCollectionModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
    
                <PurchaseSuccessModal
                    isOpen={showSuccessModal}
                    transactionId={transactionId}
                    artworkSrc={
                        selectedArtwork ? selectedArtwork.iframeSrc : ''
                    }
                    onClose={() => setShowSuccessModal(false)}
                />
    
                {showCustomConfirmModal && (
                    <ConfirmPurchaseModal
                        isOpen={showCustomConfirmModal}
                        onConfirm={handleTransactionConfirmation}
                        onCancel={() => setShowCustomConfirmModal(false)}
                        paymentDetails={confirmationDetails}
                    />
                )}
    
                {showRequestModal && (
                    <div className="custom-confirmation-modal">
                        <div className="modal-content">
                            <p>
                                Your collection request has been submitted and is
                                awaiting approval.
                            </p>
                            <button
                                onClick={() => setShowRequestModal(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Exhibition;