import React from 'react';
import { Link } from 'react-router-dom';
import './NavMenu.css';

const NavMenu = ({ isVisible, toggleMenu }) => {
    return (
        <div className={`nav-menu ${isVisible ? 'visible' : ''}`}>
            <nav>
                <ul>
                    <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
                    <li><Link to="/exhibition" onClick={toggleMenu}>Mint and Discover</Link></li>
                    <li><Link to="/inscribe" onClick={toggleMenu}>Inscribe</Link></li>
                    <li><Link to="/p5-magic" onClick={toggleMenu}>P5.js Magic</Link></li>
                    <li><Link to="/HTML-Compress-Magic" onClick={toggleMenu}>HTML Compress Magic</Link></li> 
                    <li><Link to="/Image-Compress-Magic" onClick={toggleMenu}>Image Compress Magic</Link></li>
                    <li><Link to="/inscribe-pad" onClick={toggleMenu}>Inscribe Pad</Link></li> 
                    <li><Link to="/Video-Compress-Magic" onClick={toggleMenu}>Video Compress Magic</Link></li>
                    <li><Link to="/mint" onClick={toggleMenu}>PMT</Link></li>
                    <li><Link to="/exhibition" onClick={toggleMenu}>Exhibition</Link></li>
                    <li><Link to="/gallery" onClick={toggleMenu}>Gallery</Link></li>
                    <li><Link to="/balance" onClick={toggleMenu}>Balance</Link></li>
                    <li><Link to="/transaction-history" onClick={toggleMenu}>Transaction History</Link></li>
                    <li><Link to="/what-are-people-buying?" onClick={toggleMenu}>What Are People Buying?</Link></li>
                    <li><Link to="/orders" onClick={toggleMenu}>Order Status</Link></li>
                    </ul>
            </nav>
        </div>
    );
};

export default NavMenu;
