import React, { useState, useEffect } from 'react';
import './Home.css';

const Home = () => {
const iframeSources = [
"https://ordinals.com/preview/8a0b2127624a8d51e3e35f17d5e5583cd81a47184fc3bbbd1b3160927ed9f887i0",
"https://ordinals.com/preview/83dc430d12ff87e5c48049f534e94e77472ebfc8592e70972acadabec7888db7i0",
"https://ordinals.com/preview/df7d3674cd1c356e4476c718dc2feffc6f198588310891cb2d17a92e3b85bec3i0",
"https://ordinals.com/preview/1f92d38f3968f89a85e76ae6f6fa0ca0b36c33c965cc15b5fa25b6d1e64d8374i0",
];

const getRandomIndex = (length) => Math.floor(Math.random() * length);
const [currentSlide, setCurrentSlide] = useState(getRandomIndex(iframeSources.length));

return (
<div className="home-container">
<div className="hero-section">
<div className="hero-text">
<header className="header-styling">
<h1>Expand <br></br>Imaginations</h1>
<h2>Welcome to Space Scribe Art Exchange</h2>
<hr></hr>
</header>
<section className="introduction-section">
<p>
Welcome to <strong>Space Scribe</strong>, a dedicated platform for the exchange of Ordinal art created with <em>p5.js</em> and <em>three.js</em>.
Our mission is to shine light on the vast expanse of digital art possibilities and to expand imaginations.
Here, we provide a unique space solely hosting <em>p5.js</em> and <em>three.js</em> art, showcasing the frontier of digital creativity.
</p>
</section>
</div>
<div className="hero-canvas">
<div className="background-canvas">
{/* Add your canvas or background rendering code here */}
</div>
<div className="art-gallery">
<div className="art-frame visible">
<iframe
src={iframeSources[currentSlide]}
title={`Featured Art ${currentSlide + 1}`}
frameBorder="0"
allowFullScreen
className="iframe-style"
></iframe>
</div>
</div>
</div>
</div>
<section className="form-section">
<h2>Contact Us</h2>
<form>
<div className="form-group">
<label htmlFor="name">Name</label>
<input type="text" id="name" name="name" />
</div>
<div className="form-group">
<label htmlFor="email">Email</label>
<input type="email" id="email" name="email" />
</div>
<div className="form-group">
<label htmlFor="message">Message</label>
<textarea id="message" name="message"></textarea>
</div>
<button type="submit">Send Message</button>
</form>
</section>
<footer className="footer-styling">
<h2>About Space Scribe Art Exchange</h2>
<p>Discover and acquire unique digital art pieces that open windows to new worlds. Our curated selection connects you with the cosmos through art.</p>
</footer>
</div>
);
};

export default Home;