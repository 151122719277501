import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import './PMT.css';
import ConfirmPurchaseModal from './ConfirmPurchaseModal';
import PurchaseSuccessModal from './PurchaseSuccessModal';
import WalletConnector from './WalletConnector'; // Import WalletConnector

const API_BASE_URL = 'https://serverpmt-production.up.railway.app';
const socket = io(API_BASE_URL);

const PMT = () => {
    const [selectedBlock, setSelectedBlock] = useState(null);
    const [walletAddress, setWalletAddress] = useState('');
    const [userSelectedFeeRate, setUserSelectedFeeRate] = useState(10);
    const [recommendedFees, setRecommendedFees] = useState({});
    const [isConfirming, setIsConfirming] = useState(false);
    const [isTransactionInProgress, setIsTransactionInProgress] = useState(false);
    const [showCustomConfirmModal, setShowCustomConfirmModal] = useState(false);
    const [confirmationDetails, setConfirmationDetails] = useState({});
    const [hoveredBlocks, setHoveredBlocks] = useState({});
    const [searchBlock, setSearchBlock] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [transactionId, setTransactionId] = useState('');
    const [mintedBlocks, setMintedBlocks] = useState(new Set());
    const blockRefs = useRef({});
    const [isWalletConnected, setIsWalletConnected] = useState(false);

    useEffect(() => {
        const fetchRecommendedFees = async () => {
            try {
                const response = await axios.get('https://mempool.space/api/v1/fees/recommended');
                setRecommendedFees(response.data);
                setUserSelectedFeeRate(response.data.hourFee); // Set the default fee rate to the medium fee rate
            } catch (error) {
                console.error('Error fetching recommended fees:', error);
            }
        };

        fetchRecommendedFees();

        const fetchMintedBlocks = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/minted-blocks`);
                setMintedBlocks(new Set(response.data));
            } catch (error) {
                console.error('Error fetching minted blocks:', error);
            }
        };

        fetchMintedBlocks();

        socket.on('hover', (data) => {
            setHoveredBlocks((prev) => ({ ...prev, [data.blockIndex]: data.user }));
        });

        socket.on('leave', (data) => {
            setHoveredBlocks((prev) => {
                const updated = { ...prev };
                delete updated[data.blockIndex];
                return updated;
            });
        });

        socket.on('blockMinted', (data) => {
            setMintedBlocks((prev) => new Set(prev).add(data.blockIndex));
        });

        return () => {
            socket.off('hover');
            socket.off('leave');
            socket.off('blockMinted');
        };
    }, []);

    const handleBlockHover = (blockIndex) => {
        socket.emit('hover', { blockIndex, user: socket.id });
    };

    const handleBlockLeave = (blockIndex) => {
        socket.emit('leave', { blockIndex, user: socket.id });
    };

    const handleBlockClick = (blockIndex) => {
        if (!isTransactionInProgress && !mintedBlocks.has(blockIndex)) {
            if (!isWalletConnected) {
                alert('Please connect your UniSat wallet to proceed.');
                return;
            }
            setSelectedBlock(blockIndex);
            setIsConfirming(true);
        }
    };

    const handleConfirmPurchase = async () => {
        if (isTransactionInProgress) {
            console.log('Transaction already in progress - Ignoring additional request');
            return;
        }

        if (!selectedBlock || !walletAddress) {
            alert('Please connect your wallet and select a block.');
            return;
        }

        setIsTransactionInProgress(true);
        console.log('Transaction started');
        const feeRate = parseInt(userSelectedFeeRate, 10);

        try {
            const purchaseData = {
                blockIndex: selectedBlock,
                userSelectedFeeRate: feeRate,
                userSelectedReceiverAddress: walletAddress,
            };

            console.log('Sending purchase data to server', purchaseData);
            const response = await axios.post(`${API_BASE_URL}/api/inscribe`, purchaseData);
            console.log('Purchase data response', response.data);
            const result = response.data;

            setConfirmationDetails({
                payAddressAmount: result.payAddressAmount,
                payAddress: result.payAddress,
                receiverAddress: result.receiverAddress,
                feeRate: userSelectedFeeRate,
            });
            setShowCustomConfirmModal(true);
        } catch (error) {
            console.error("Error during the purchase request:", error);
            alert('An error occurred while processing your purchase.');
        } finally {
            setIsTransactionInProgress(false);
            setIsConfirming(false);
        }
    };

    const handlePasteAddress = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setWalletAddress(text);
        } catch (error) {
            console.error('Failed to read clipboard contents:', error);
        }
    };

    const handleTransactionConfirmation = async () => {
        setShowCustomConfirmModal(false);

        if (window.unisat) {
            try {
                console.log('Sending payment via UniSat for the inscription fee');
                const txId = await window.unisat.sendBitcoin(
                    confirmationDetails.payAddress,
                    confirmationDetails.payAddressAmount,
                    { feeRate: confirmationDetails.feeRate }
                );
                console.log('Inscription fee transaction completed', txId);

                if (txId) {
                    console.log('Transaction ID:', txId);
                    setTransactionId(txId);
                    setMintedBlocks(prev => new Set(prev).add(selectedBlock)); // Mark the block as minted
                    socket.emit('mintBlock', { blockIndex: selectedBlock }); // Broadcast the minted block
                    setSelectedBlock(null);  // Reset selected block
                    setShowSuccessModal(true);  // Show success modal
                } else {
                    console.log('No transaction ID returned from payment');
                }
            } catch (error) {
                console.error("Error during the payment transaction:", error);
                alert('An error occurred while processing your payment.');
            }
        }
    };

    const handleSearchBlock = () => {
        const blockIndex = parseInt(searchBlock, 10);
        if (blockIndex >= 0 && blockIndex < 10000) {
            blockRefs.current[blockIndex].scrollIntoView({ behavior: 'smooth' });
        } else {
            alert('Invalid block number. Please enter a number between 0 and 9999.');
        }
    };

    const handleWalletConnected = (address) => {
        setWalletAddress(address);
        setIsWalletConnected(true);
    };

    return (
        <div className="pmt-container">
            <h1>PMT MINT</h1>
            <WalletConnector onConnected={handleWalletConnected} /> {/* Add WalletConnector */}
            <div className="search-container">
                <input
                    type="number"
                    value={searchBlock}
                    onChange={(e) => setSearchBlock(e.target.value)}
                    placeholder="Enter block number"
                />
                <button onClick={handleSearchBlock}>Search Block</button>
            </div>
            <div className="blocks-container"> {/* Add container for blocks */}
                <div className="pmt-grid">
                    {[...Array(10000).keys()].map(blockIndex => (
                        <div
                            key={blockIndex}
                            ref={(el) => (blockRefs.current[blockIndex] = el)}
                            className={`pmt-block ${selectedBlock === blockIndex ? 'selected' : ''} ${hoveredBlocks[blockIndex] ? 'hovered' : ''} ${mintedBlocks.has(blockIndex) ? 'minted' : ''}`}
                            onClick={() => handleBlockClick(blockIndex)}
                            onMouseEnter={() => handleBlockHover(blockIndex)}
                            onMouseLeave={() => handleBlockLeave(blockIndex)}
                        >
                            Block {blockIndex}
                            {hoveredBlocks[blockIndex] && <span className="hover-emoji">👾</span>}
                        </div>
                    ))}
                </div>
            </div>
            {isConfirming && (
                <div className="pmt-confirm-container">
                    <h2>Confirm Purchase</h2>
                    <p>You are about to inscribe block: {selectedBlock}</p>
                    <div>
                        <label>Receiver Address:</label>
                        <input
                            type="text"
                            value={walletAddress}
                            onChange={(e) => setWalletAddress(e.target.value)}
                            placeholder="Enter receiver address"
                        />
                        <button onClick={handlePasteAddress}>Paste Address</button>
                    </div>
                    <div>
                        <label>Fee Rate (sat/vB):</label>
                        <input
                            type="number"
                            value={userSelectedFeeRate}
                            onChange={(e) => setUserSelectedFeeRate(e.target.value)}
                            min="1"
                        />
                        {recommendedFees.fastestFee && (
                            <div>
                                <p>Fastest Fee: {recommendedFees.fastestFee} sat/vB</p>
                                <p>Half Hour Fee: {recommendedFees.halfHourFee} sat/vB</p>
                                <p>Hour Fee: {recommendedFees.hourFee} sat/vB</p>
                            </div>
                        )}
                    </div>
                    <button onClick={handleConfirmPurchase} disabled={isTransactionInProgress}>Confirm Purchase</button>
                    <button onClick={() => setIsConfirming(false)}>Cancel</button>
                </div>
            )}
            {showCustomConfirmModal && (
                <ConfirmPurchaseModal
                    isOpen={showCustomConfirmModal}
                    onConfirm={handleTransactionConfirmation}
                    onCancel={() => setShowCustomConfirmModal(false)}
                    paymentDetails={confirmationDetails}
                />
            )}
            <PurchaseSuccessModal
                isOpen={showSuccessModal}
                transactionId={transactionId}
                onClose={() => setShowSuccessModal(false)}
            />
        </div>
    );
};

export default PMT;
