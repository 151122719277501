import React, { useState } from 'react';
import NavMenu from './NavMenu';
import WalletConnector from './WalletConnector';
import './Header.css';

const Header = () => {
    const [isNavVisible, setIsNavVisible] = useState(false);

    const toggleMenu = () => {
        setIsNavVisible(!isNavVisible);
    };

    return (
        <>
            <div className="header-container">
                <button className={`menu-btn ${isNavVisible ? 'open' : ''}`} onClick={toggleMenu}>
                    {isNavVisible ? 'X' : <span className="menu-icon">&#9776;</span>}
                </button>
                <div className="site-title">http://www.spacescribe.xyz</div>
                <WalletConnector onConnected={(address) => console.log('Connected:', address)} />
            </div>
            <NavMenu isVisible={isNavVisible} toggleMenu={toggleMenu} />
        </>
    );
};

export default Header;
