// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletConnector_walletConnectorContainer__v1cXs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* Styles from Header.css */
.WalletConnector_walletConnectButton__6XMNU {
  font-family: var(--secondary-font);
  background-color: var(--dark-gray);
  color: var(--white);
  border: none;
  padding: var(--padding-small) var(--padding-default);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color var(--transition-default);
}

.WalletConnector_walletConnectButton__6XMNU:hover {
  background-color: #333;
}

.WalletConnector_walletAddress__XRWVC {
  color: #ebebeb;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 8px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 8px;
}

@media (max-width: 768px) {
  .WalletConnector_walletConnectorContainer__v1cXs {
    top: 5px;
  }

  .WalletConnector_walletConnectButton__6XMNU, .WalletConnector_walletAddress__XRWVC {
    padding: 8px 15px;
    font-size: 12px;
  }

  .WalletConnector_walletAddress__XRWVC {
    font-size: 10px;
    padding: 6px;
    max-width: 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/WalletConnector.module.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA,2BAA2B;AAC3B;EACE,kCAAkC;EAClC,kCAAkC;EAClC,mBAAmB;EACnB,YAAY;EACZ,oDAAoD;EACpD,mCAAmC;EACnC,eAAe;EACf,sDAAsD;AACxD;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,kCAAkC;EAClC,eAAe;EACf,oCAAoC;EACpC,YAAY;EACZ,mBAAmB;EACnB,wCAAwC;EACxC,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE;IACE,QAAQ;EACV;;EAEA;IACE,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,gBAAgB;EAClB;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');\n\n.walletConnectorContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}\n\n/* Styles from Header.css */\n.walletConnectButton {\n  font-family: var(--secondary-font);\n  background-color: var(--dark-gray);\n  color: var(--white);\n  border: none;\n  padding: var(--padding-small) var(--padding-default);\n  border-radius: var(--border-radius);\n  cursor: pointer;\n  transition: background-color var(--transition-default);\n}\n\n.walletConnectButton:hover {\n  background-color: #333;\n}\n\n.walletAddress {\n  color: #ebebeb;\n  font-family: 'Poppins', sans-serif;\n  font-size: 12px;\n  background-color: rgba(0, 0, 0, 0.8);\n  padding: 8px;\n  border-radius: 15px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n  max-width: 250px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  margin-top: 8px;\n}\n\n@media (max-width: 768px) {\n  .walletConnectorContainer {\n    top: 5px;\n  }\n\n  .walletConnectButton, .walletAddress {\n    padding: 8px 15px;\n    font-size: 12px;\n  }\n\n  .walletAddress {\n    font-size: 10px;\n    padding: 6px;\n    max-width: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"walletConnectorContainer": `WalletConnector_walletConnectorContainer__v1cXs`,
	"walletConnectButton": `WalletConnector_walletConnectButton__6XMNU`,
	"walletAddress": `WalletConnector_walletAddress__XRWVC`
};
export default ___CSS_LOADER_EXPORT___;
