// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inscribe-pad-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: linear-gradient(135deg, #2e2b2b, #454040); /* Slightly darker gradient for 3D effect */
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow for 3D effect */
  min-height: 100vh; /* Ensure full height of the viewport */
  box-sizing: border-box;
}

.inscribe-pad {
  border: 1px solid #000;
  margin: 50px auto;
  width: 600px;
  height: 600px;
  cursor: crosshair;
  display: block;
  background-color: #ffffff; /* Ensure it has a background color */
}

/* Additional styles for responsive layout */
@media (max-width: 768px) {
  .inscribe-pad {
    width: 100%;
    height: 100vw; /* Maintain aspect ratio */
    max-width: 600px;
    max-height: 600px;
  }

  .color-picker, .submission-controls, .result {
    width: 100%;
    padding: 0 20px;
  }

  .submit-button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/InscribePad.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,qDAAqD,EAAE,2CAA2C;EAClG,kBAAkB;EAClB,yCAAyC,EAAE,kCAAkC;EAC7E,iBAAiB,EAAE,uCAAuC;EAC1D,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,cAAc;EACd,yBAAyB,EAAE,qCAAqC;AAClE;;AAEA,4CAA4C;AAC5C;EACE;IACE,WAAW;IACX,aAAa,EAAE,0BAA0B;IACzC,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,gBAAgB;EAClB;AACF","sourcesContent":[".inscribe-pad-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 20px;\n  background: linear-gradient(135deg, #2e2b2b, #454040); /* Slightly darker gradient for 3D effect */\n  border-radius: 8px;\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow for 3D effect */\n  min-height: 100vh; /* Ensure full height of the viewport */\n  box-sizing: border-box;\n}\n\n.inscribe-pad {\n  border: 1px solid #000;\n  margin: 50px auto;\n  width: 600px;\n  height: 600px;\n  cursor: crosshair;\n  display: block;\n  background-color: #ffffff; /* Ensure it has a background color */\n}\n\n/* Additional styles for responsive layout */\n@media (max-width: 768px) {\n  .inscribe-pad {\n    width: 100%;\n    height: 100vw; /* Maintain aspect ratio */\n    max-width: 600px;\n    max-height: 600px;\n  }\n\n  .color-picker, .submission-controls, .result {\n    width: 100%;\n    padding: 0 20px;\n  }\n\n  .submit-button {\n    width: 100%;\n    padding: 10px;\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
