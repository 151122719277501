
import React from 'react';
import './PurchaseSuccessModal.css'; 

const PurchaseSuccessModal = ({ isOpen, transactionId, artworkSrc, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <h2>Purchase Successful!</h2>
                <p>Your transaction ID is: <strong>{transactionId}</strong></p>
                <img src={artworkSrc} alt="Purchased Art" className="purchased-art-image" />
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default PurchaseSuccessModal;
